import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Input, Select, Switch, TimePicker } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTimeReport } from '../../../hooks/Time';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const IndividualForm = ({
  form,
  FormTag,
  record,
  activities,
  project,
  projects,
  projectChange,
  hideOk,
}) => {
  const { getFieldDecorator, getFieldValue } = form;
  const selectActivity = getFieldValue('selectActivity') !== false;
  const projectValue = getFieldValue('project');

  const { t } = useTranslation();

  const { actualDate, changeTime, disabledHours } = useTimeReport({ form });

  useEffect(() => {
    if (projectChange)
      projectChange(
        record &&
          record.activity &&
          record.activity.project &&
          record.activity.project.id
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const onBlurBilledTime = (_, timeString) => {
    changeTime(timeString, 'billedTime');
  };

  const onBlurNotBilledTime = (_, timeString) => {
    changeTime(timeString, 'notBilledTime');
  };

  return (
    <FormTag layout="horizontal" autoComplete="off">
      {project && (
        <FormTag.Item label={t('timeRecord.project')} {...formItemLayout}>
          {getFieldDecorator('project', {
            initialValue:
              record &&
              record.activity &&
              record.activity.project &&
              record.activity.project.id,
            rules: [
              { required: true, message: t('timeRecord.requiredProject') },
            ],
          })(
            <Select
              onChange={value => {
                form.setFieldsValue({ activity: null });
                if (projectChange) projectChange(value);
              }}
              disabled={hideOk}
              showSearch
              optionFilterProp="children"
            >
              {projects &&
                projects.map(x => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
            </Select>
          )}
        </FormTag.Item>
      )}
      <FormTag.Item label={t('timeRecord.selectActivity')} {...formItemLayout}>
        {getFieldDecorator('selectActivity', {
          initialValue: true,
          valuePropName: 'checked',
          rules: [],
        })(
          <Switch
            disabled={hideOk}
            onChange={() => form.setFieldsValue({ activity: undefined })}
            checkedChildren="Si"
            unCheckedChildren="No"
          />
        )}
      </FormTag.Item>
      {selectActivity && (
        <FormTag.Item label={t('timeRecord.activity')} {...formItemLayout}>
          {getFieldDecorator('activity', {
            initialValue:
              activities && record && record.activity && record.activity.id,
            rules: [
              { required: true, message: t('timeRecord.requiredActivity') },
            ],
          })(
            <Select disabled={hideOk} showSearch optionFilterProp="children">
              {((project && projectValue) || !project || hideOk) &&
                activities &&
                activities.map(x => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
            </Select>
          )}
        </FormTag.Item>
      )}
      {!selectActivity && (
        <FormTag.Item label={t('timeRecord.activity')} {...formItemLayout}>
          {getFieldDecorator('activity', {
            initialValue: record && record.activity && record.activity.id,
            rules: [
              { required: true, message: t('timeRecord.requiredActivity') },
            ],
          })(<Input disabled={hideOk} />)}
        </FormTag.Item>
      )}
      <FormTag.Item label={t('timeRecord.billedTime')} {...formItemLayout}>
        {getFieldDecorator('billedTime', {
          initialValue:
            record && record.billedTime
              ? moment(
                  `${actualDate.format('YYYY-MM-DD')} ${record.billedTime.split(
                    ':'
                  )[0] || 0}:
                ${record.billedTime.split(':')[1] || 0}`
                )
              : null,
          rules: [],
        })(
          <TimePicker
            disabled={hideOk}
            format="HH:mm"
            style={{ width: '50%' }}
            onChange={onBlurBilledTime}
            hideDisabledOptions
            disabledHours={disabledHours}
          />
        )}
      </FormTag.Item>
      <FormTag.Item label={t('timeRecord.notBilledTime')} {...formItemLayout}>
        {getFieldDecorator('notBilledTime', {
          initialValue:
            record && record.notBilledTime
              ? moment(
                  `${actualDate.format(
                    'YYYY-MM-DD'
                  )} ${record.notBilledTime.split(':')[0] || 0}:
                ${record.notBilledTime.split(':')[1] || 0}`
                )
              : null,
          rules: [],
        })(
          <TimePicker
            disabled={hideOk}
            format="HH:mm"
            style={{ width: '50%' }}
            onChange={onBlurNotBilledTime}
            hideDisabledOptions
            disabledHours={disabledHours}
          />
        )}
      </FormTag.Item>
      <FormTag.Item label={t('timeRecord.time')} {...formItemLayout}>
        {getFieldDecorator('time', {
          initialValue: moment(
            `${actualDate.format('YYYY-MM-DD')} ${(record && record.hours) ||
              0}:
                ${(record && record.minutes) || 0}`
          ),
          rules: [{ required: true, message: t('timeRecord.requeridTime') }],
        })(<TimePicker disabled format="HH:mm" style={{ width: '50%' }} />)}
      </FormTag.Item>
      <FormTag.Item label={t('timeRecord.date')} {...formItemLayout}>
        {getFieldDecorator('date', {
          initialValue: moment((record && record.date) || new Date()),
          rules: [{ required: true, message: t('timeRecord.requeridDate') }],
        })(
          <DatePicker
            disabled={hideOk}
            format="DD/MM/YYYY"
            style={{ width: '50%' }}
          />
        )}
      </FormTag.Item>
      <FormTag.Item label={t('timeRecord.description')} {...formItemLayout}>
        {getFieldDecorator('description', {
          initialValue: record && record.description,
          rules: [],
        })(
          <Input.TextArea
            disabled={hideOk}
            maxLength={1000}
            style={{ minHeight: 100 }}
          />
        )}
      </FormTag.Item>
    </FormTag>
  );
};

IndividualForm.propTypes = {
  project: PropTypes.bool,
  hideOk: PropTypes.bool,
  form: PropTypes.object,
  record: PropTypes.object,
  activities: PropTypes.array,
  projects: PropTypes.array,
  FormTag: PropTypes.any,
  projectChange: PropTypes.func,
};

export default IndividualForm;
