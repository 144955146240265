import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { show } from '../../../utils/message';
import ChangeStateForm from './ChangeStateForm';

const buttonProps = {
  size: 'large',
  type: 'primary',
};

const ChangeState = ({
  id,
  transitions,
  children,
  history,
  messageValidations,
}) => {
  const [visibility, setVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  const refresh = () => {
    const url = history.location.pathname;
    history.replace('/reload');
    setTimeout(() => {
      history.push(url);
    });
  };

  const submit = async (values, update) => {
    setLoading(true);
    const transition = transitions.find(x => x.end.id === values.nextState);
    const cyclic = (transition && transition.end.cyclic) || false;
    // return;
    const { updateProjectState } = (await update({
      variables: {
        state: values.nextState,
        responsible: values.responsible,
        visualizers: values.visualizers,
        project: id,
        cyclic,
      },
    })).data;
    const info = updateProjectState.message.split('info#').length > 1;
    const message = info
      ? updateProjectState.message.split('info#')[1]
      : updateProjectState.message;
    if (info) messageValidations(message);
    show({
      type: updateProjectState.result ? 'success' : 'warning',
      message,
    });

    setVisibility(false);
    setLoading(false);
    if (updateProjectState.result) refresh();
  };

  return (
    <Fragment>
      <Button {...buttonProps} onClick={() => setVisibility(!visibility)}>
        {children}
      </Button>{' '}
      <ChangeStateForm
        visibility={visibility}
        transitions={transitions}
        projectId={id}
        onSubmit={submit}
        onCancel={() => setVisibility(false)}
        loading={loading}
      />
    </Fragment>
  );
};

ChangeState.propTypes = {
  id: PropTypes.string,
  transitions: PropTypes.array,
  children: PropTypes.any,
  history: PropTypes.object,
  messageValidations: PropTypes.func,
};

export default ChangeState;
