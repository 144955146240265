import gql from 'graphql-tag';
import { response } from '../utils/common';

const FIELDS_TEMPLATE = `
  id
  name
  description
  type {
    id
    name
    structure {
      id
      name
    }
  }
  participants {
    id
    user {
      id
      name
    }
    charge {
      id
      name
    }
  }
  activities {
    id
    name
    description
    estimate
  }
`;

export const TEMPLATE = gql`
  query Template($id: ID) {
    template(id: $id) {
      ${FIELDS_TEMPLATE}
    }
  }
`;

export const TEMPLATES = gql`
  query Templates {
    templates {
      ${FIELDS_TEMPLATE}
    }
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($id: ID) {
    deleteTemplate(id: $id) {
      ${response}
    }
  }
`;

export const UPSERT_TEMPLATE = gql`
  mutation UpsertTemplate(
    $id: ID 
    $name: String 
    $description: String 
    $type: ID 
    $participants: [ParticipantInput]) {
    upsertTemplate(
      id: $id 
      name: $name 
      description: $description 
      type: $type 
      participants: $participants) {
      ${response}
      dataResult {
        ${FIELDS_TEMPLATE}
      }
    }
  }
`;
