import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { Query } from 'react-apollo';
import CustomTheme from './CustomTheme';
import WrapperCenter from './WrapperCenter';
import { COMPANY_DOMAIN } from '../../graphql';
import { PRIMARY_COLOR } from '../../config/constants';

const ChangeTheme = ({ children, setChangeColor }) => {
  const getColor = company => {
    let colors = PRIMARY_COLOR;
    if (company) colors = company.primaryColor;
    return colors;
  };

  return (
    <Query query={COMPANY_DOMAIN} fetchPolicy="network-only">
      {({ data, loading, error }) => {
        if (loading) {
          return (
            <WrapperCenter>
              <Spin className="custom-layout-spin" />
            </WrapperCenter>
          );
        }
        return (
          <Fragment>
            <CustomTheme
              colorBase={data ? getColor(data.companyDomain) : PRIMARY_COLOR}
              change
              setChangeColor={setChangeColor}
            />
            {children}
          </Fragment>
        );
      }}
    </Query>
  );
};

ChangeTheme.propTypes = {
  children: PropTypes.any,
  setChangeColor: PropTypes.func,
};

export default ChangeTheme;
