import gql from 'graphql-tag';
import { response } from '../utils/common';

const logoFields = `
  id
  name
  url
  size
`;

export const COMPANIES = gql`
  query Companies {
    companies {
      id
      name
      identification
      domain
      primaryColor
      shortLogo {
        ${logoFields}
      }
      logo {
        ${logoFields}
      }
      license {
        id
        name
      }
      legalText
    }
  }
`;

export const COMPANY_DOMAIN = gql`
  query CompanyDomain {
    companyDomain {
      id
      name
      domain
      primaryColor
      legalText
    }
  }
`;

export const COMPANY = gql`
  query Company($id: ID) {
    company(id: $id) {
      id
      name
      domain
      responsible
      responsibleMail
      phone
      address
      identificationType {
        id
        name
      }
      identification
      shortLogo {
        ${logoFields}
      }
      logo {
        ${logoFields}
      }
      primaryColor
      secondaryColor
      license {
        id
        name
      }
      legalText
    }
  }
`;

export const UPSERT_COMPANY = gql`
  mutation UpsertCompany(
    $id: ID
    $name: String!
    $domain: String
    $responsible: String
    $responsibleMail: String
    $phone: String
    $address: String
    $identificationType: ID
    $identification: String
    $shortLogo: [Upload]
    $logo: [Upload]
    $primaryColor: String
    $secondaryColor: String
    $license: ID
    $legalText: String
  ) {
    upsertCompany(
      id: $id
      name: $name
      domain: $domain
      responsible: $responsible
      responsibleMail: $responsibleMail
      phone: $phone
      address: $address
      identificationType: $identificationType
      identification: $identification
      shortLogo: $shortLogo
      logo: $logo
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      license: $license
      legalText: $legalText
    ) {
      ${response}
    }
  }
`;

export const COMPANIES_LIST = gql`
  query CompaniesList {
    companies {
      domain
      name
    }
  }
`;
