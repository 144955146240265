import gql from 'graphql-tag';
import { response } from '../utils/common';

export const USERS = gql`
  query Users {
    users {
      id
      name
      charge
      photo {
        id
        url
      }
    }
  }
`;

export const USERS_PARTICIPANTS = gql`
  query Users($projectId: ID) {
    usersParticipants(projectId: $projectId) {
      id
      name
    }
  }
`;

export const USER_NAME = gql`
  query User($id: ID) {
    user(id: $id) {
      id
      name
      charge
    }
  }
`;

export const USER_LIST = gql`
  query Users {
    users {
      id
      name
    }
  }
`;

export const SIGNUP = gql`
  mutation Signup($name: String!, $email: String!, $password: String!) {
    signup(name: $name, email: $email, password: $password) {
      token
      user {
        id
        name
        email
        domain
      }
      signup @client
    }
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!, $domain: String) {
    login(email: $email, password: $password, domain: $domain) {
      token
      user {
        id
        name
        email
        active
        domain
      }
      roles {
        id
        name
        __typename
      }
      menus {
        id
        name
        interactions {
          id
          name
          __typename
        }
        __typename
      }
      logo
      logoExpanded
      photo
      response {
          ${response}
      }
      login @client
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout @client
  }
`;

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    userLogged @client {
      isLoggedIn
      isActive
      roles {
        id
        name
      }
      menus {
        id
        name
        interactions {
          id
          name
        }
      }
      logo
      logoExpanded
      photo
      currentUser
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $password: String!
    $confirmPassword: String!
    $email: String!
    $token: String
  ) {
    resetPassword(
      password: $password
      confirmPassword: $confirmPassword
      email: $email
      token: $token
    ) {
      message
      status
      result
    }
  }
`;

export const RESPONSIBLE = gql`
  query ResponsibleState($state: ID!) {
    responsibleState(state: $state) {
      id
      name
    }
  }
`;

export const USER_LIST_ROLE = gql`
  query UsersProject($projectId: ID!) {
    usersProject(projectId: $projectId) {
      id
      name
      roles {
        name
        id
      }
    }
  }
`;

export const USERS_PROJECT = gql`
  query UsersProject($projectId: ID!) {
    usersProject(projectId: $projectId) {
      id
      name
    }
  }
`;

export const RESPONSIBLE_USER = gql`
  query Responsible($projectId: ID!, $userId: ID!) {
    responsibleUser(projectId: $projectId, userId: $userId) {
      id
    }
  }
`;

export const PROFILE = gql`
  query Profile {
    profile {
      id
      name
      email
      charge
      photo {
        id
        name
        url
        size
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $name: String
    $email: String
    $charge: String
    $photo: [Upload]
  ) {
    updateProfile(name: $name email: $email charge: $charge photo: $photo) {
      ${response}
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String $password: String $confirm: String) {
    changePassword(oldPassword: $oldPassword  password: $password confirm: $confirm) {
      ${response}
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($company: String $email: String) {
    forgotPassword(company: $company email: $email) {
      ${response}
    }
  }
`;
