import React, { Fragment } from 'react';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';

const ApolloMutation = ({ setFunction, state, mutation, children }) => (
  <Mutation mutation={mutation.mutation} {...mutation.props}>
    {action => {
      if (state === undefined) {
        setFunction(() => action);
      }
      return <Fragment>{children}</Fragment>;
    }}
  </Mutation>
);

ApolloMutation.propTypes = {
  setFunction: PropTypes.func,
  mutation: PropTypes.object,
  state: PropTypes.any,
  children: PropTypes.any,
};

export default ApolloMutation;
