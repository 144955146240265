import gql from 'graphql-tag';
import { response } from '../utils/common';

export const ROLE = gql`
  query Role($id: ID) {
    role(id: $id) {
      id
      name
      permissions {
        id
        menu {
          id
          name
        }
        interaction {
          id
          name
        }
      }
    }
  }
`;

export const UPSERT_ROLE = gql`
  mutation UpsertRole(
    $id: ID
    $name: String!
    $permissions: [PermissionInput]
  ) {
    upsertRole(id: $id, name: $name, permissions: $permissions) {
      ${response}
    }
  }
`;

export const ROLES = gql`
  query Roles {
    roles {
      id
      name
    }
  }
`;
