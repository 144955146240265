import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

const CustomTab = ({ type, text }) => (
  <div className="custom-tab-pane">
    <Icon type={type} key="icon" />
    <p key="text">{text}</p>
    <div key="arrow" className="custom-active-arrow" />
  </div>
);

CustomTab.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
};

export default CustomTab;
