import { lazy } from 'react';
import { createRoute } from '../utils/general';
import { LOGGED, GUEST } from './constants';

const Async404 = lazy(() => import('../pages/other/404.js'));
const AsyncLogin = lazy(() => import('../pages/security/Login.js'));
const AsyncResetPass = lazy(() => import('../pages/security/ResetPassword.js'));
const AsyncUsers = lazy(() => import('../pages/administration/Users.js'));
const AsyncUser = lazy(() => import('../pages/administration/User.js'));
const AsyncStages = lazy(() => import('../pages/administration/Stages.js'));
const AsyncStage = lazy(() => import('../pages/administration/Stage.js'));
const AsyncFlows = lazy(() => import('../pages/administration/flow/Flows.js'));
const AsyncFlow = lazy(() => import('../pages/administration/flow/Flow.js'));
const AsyncState = lazy(() => import('../pages/administration/flow/State.js'));
const AsyncProjects = lazy(() => import('../pages/management/Projects.js'));
const AsyncProject = lazy(() => import('../pages/management/Project.js'));
const AsyncCharges = lazy(() => import('../pages/administration/Charges.js'));
const AsyncCharge = lazy(() => import('../pages/administration/Charge.js'));
const AsyncCity = lazy(() => import('../pages/administration/City.js'));
const AsyncCities = lazy(() => import('../pages/administration/Cities.js'));
const AsyncCountry = lazy(() => import('../pages/administration/Country.js'));
const AsyncCountries = lazy(() =>
  import('../pages/administration/Countries.js')
);
const AsyncProvince = lazy(() => import('../pages/administration/Province.js'));
const AsyncProvinces = lazy(() =>
  import('../pages/administration/Provinces.js')
);
const AsyncCustomer = lazy(() => import('../pages/administration/Customer.js'));
const AsyncCustomers = lazy(() =>
  import('../pages/administration/Customers.js')
);
const AsyncForgotPass = lazy(() =>
  import('../pages/security/ForgotPassword.js')
);
const AsyncRecordTypes = lazy(() =>
  import('../pages/administration/RecordTypes.js')
);
const AsyncRecordType = lazy(() =>
  import('../pages/administration/RecordType.js')
);
const AsyncProjectTypes = lazy(() =>
  import('../pages/administration/ProjectTypes.js')
);
const AsyncProjectType = lazy(() =>
  import('../pages/administration/ProjectType.js')
);
const AsyncNotifications = lazy(() =>
  import('../pages/administration/Notifications.js')
);
const AsyncNotification = lazy(() =>
  import('../pages/administration/Notification.js')
);
const AsyncDiagram = lazy(() =>
  import('../pages/administration/flow/Diagram.js')
);
const AsyncRoles = lazy(() => import('../pages/security/Roles.js'));
const AsyncRole = lazy(() => import('../pages/security/Role.js'));
const AsyncTemplate = lazy(() => import('../pages/management/Template.js'));
const AsyncTemplates = lazy(() => import('../pages/management/Templates.js'));
const AsyncCompanies = lazy(() => import('../pages/security/Companies.js'));
const AsyncCompany = lazy(() => import('../pages/security/Company.js'));
const AsyncProfile = lazy(() => import('../pages/security/Profile.js'));
const AsyncTimeReport = lazy(() => import('../pages/report/TimeReport.js'));
const AsyncDashboar = lazy(() => import('../pages/home/Dashboard'));
const AsyncFollowupTypes = lazy(() =>
  import('../pages/administration/FollowupTypes.js')
);
const AsyncFollowupType = lazy(() =>
  import('../pages/administration/FollowupType.js')
);
const AsyncQualifications = lazy(() =>
  import('../pages/diagnosis/Qualifications')
);
const AsyncQualification = lazy(() =>
  import('../pages/diagnosis/Qualification')
);
const AsyncDiagnosticses = lazy(() =>
  import('../pages/diagnosis/Diagnosticses')
);
const AsyncDiagnostics = lazy(() => import('../pages/diagnosis/Diagnostics'));
const AsyncPerfomDiagnosis = lazy(() =>
  import('../pages/diagnosis/PerfomDiagnostics')
);
const AsyncAnswerDiagnosis = lazy(() =>
  import('../pages/diagnosis/AnswerDiagnosis')
);
const AsyncMyDiagnosticses = lazy(() =>
  import('../pages/diagnosis/MyDiagnosticses')
);
const AsyncGraphics = lazy(() => import('../pages/diagnosis/Graphics'));
const AsyncReportProject = lazy(() => import('../pages/report/Project'));
const AsyncReportTime = lazy(() => import('../pages/report/Time'));
const AsyncDetailedProjectReport = lazy(() =>
  import('../pages/report/detailedProjectReport')
);
const AsyncBillings = lazy(() => import('../pages/billings/billings'));

export default [
  createRoute('/login/:domain?', AsyncLogin, '*', GUEST, false, false),
  createRoute(
    '/forgotPassword/:company?',
    AsyncForgotPass,
    '*',
    GUEST,
    false,
    false
  ),
  createRoute('/countries', AsyncCountries, 'countries', LOGGED),
  createRoute('/country/:id?', AsyncCountry, 'countries', LOGGED),
  createRoute('/provinces', AsyncProvinces, 'provinces', LOGGED),
  createRoute('/province/:id?', AsyncProvince, 'provinces', LOGGED),
  createRoute('/roles', AsyncRoles, 'roles', LOGGED),
  createRoute('/role/:id?', AsyncRole, 'roles', LOGGED),
  createRoute('/cities', AsyncCities, 'cities', LOGGED),
  createRoute('/city/:id?', AsyncCity, 'cities', LOGGED),
  createRoute('/users', AsyncUsers, 'users', LOGGED),
  createRoute('/user/:id?', AsyncUser, 'users', LOGGED),
  createRoute('/stages', AsyncStages, 'stages', LOGGED),
  createRoute('/stage/:id?', AsyncStage, 'stages', LOGGED),
  createRoute('/recordTypes', AsyncRecordTypes, 'recordTypes', LOGGED),
  createRoute('/recordType/:id?', AsyncRecordType, 'recordTypes', LOGGED),
  createRoute('/projectTypes', AsyncProjectTypes, 'projectTypes', LOGGED),
  createRoute('/projectType/:id?', AsyncProjectType, 'projectTypes', LOGGED),
  createRoute('/flows', AsyncFlows, 'flows', LOGGED),
  createRoute('/flow/:id?', AsyncFlow, 'flows', LOGGED),
  createRoute('/notifications', AsyncNotifications, 'notifications', LOGGED),
  createRoute('/notification/:id?', AsyncNotification, 'notifications', LOGGED),
  createRoute('/diagram/:id?', AsyncDiagram, 'flows', LOGGED),
  createRoute('/state/:flowId?/:id?', AsyncState, 'flows', LOGGED),
  createRoute('/customers', AsyncCustomers, 'customers', LOGGED),
  createRoute('/customer/:id?', AsyncCustomer, 'customers', LOGGED),
  createRoute('/charges', AsyncCharges, 'charges', LOGGED),
  createRoute('/charge/:id?', AsyncCharge, 'charges', LOGGED),
  createRoute('/template/:id?', AsyncTemplate, 'templates', LOGGED),
  createRoute('/templates', AsyncTemplates, 'templates', LOGGED),
  createRoute('/companies', AsyncCompanies, 'companies', LOGGED),
  createRoute('/company/:id?', AsyncCompany, 'companies', LOGGED),
  createRoute('/projects', AsyncProjects, 'projects', LOGGED),
  createRoute(
    '/project/:template?/:type?/:id?/:origin?',
    AsyncProject,
    'projects',
    LOGGED
  ),
  createRoute(
    '/resetPassword/:type?/:token?',
    AsyncResetPass,
    '*',
    LOGGED,
    false,
    false
  ),
  createRoute('/profile', AsyncProfile, 'profile', LOGGED),
  createRoute('/timeReport', AsyncTimeReport, 'timeReport', LOGGED),
  createRoute('/dashboard', AsyncDashboar, 'dashboard', LOGGED),
  createRoute('/followupTypes', AsyncFollowupTypes, 'followupTypes', LOGGED),
  createRoute('/followupType/:id?', AsyncFollowupType, 'followupTypes', LOGGED),
  createRoute('/qualifications', AsyncQualifications, 'qualifications', LOGGED),
  createRoute(
    '/qualification/:id?',
    AsyncQualification,
    'qualifications',
    LOGGED
  ),
  createRoute('/diagnosticses', AsyncDiagnosticses, 'diagnosticses', LOGGED),
  createRoute('/diagnostics/:id?', AsyncDiagnostics, 'diagnosticses', LOGGED),
  createRoute(
    '/perfomDiagnostics',
    AsyncPerfomDiagnosis,
    'perfomDiagnostics',
    LOGGED
  ),
  createRoute(
    '/answerDiagnosis/:id?/:idDiagnosis?/:idCustomer?',
    AsyncAnswerDiagnosis,
    'perfomDiagnostics',
    LOGGED
  ),
  createRoute(
    '/myDiagnosticses',
    AsyncMyDiagnosticses,
    'myDiagnosticses',
    LOGGED
  ),
  createRoute(
    '/graphics/:id?/:idDiagnosis?/:idCustomer?',
    AsyncGraphics,
    'myDiagnosticses',
    LOGGED,
    true,
    true
  ),
  createRoute('/report/projects', AsyncReportProject, 'report.project', LOGGED),
  createRoute('/report/time', AsyncReportTime, 'report.time', LOGGED),
  createRoute(
    '/report/detailedProject',
    AsyncDetailedProjectReport,
    'report.detailedProject',
    LOGGED,
    true,
    true
  ),
  createRoute('/billings', AsyncBillings, 'billings', LOGGED),
  // This route must be always at the end
  createRoute(undefined, Async404, '*', LOGGED),
];
