import gql from 'graphql-tag';
import { response } from '../utils/common';

export const ACTA_FIELDS = `
  id
  customer
  name
  date
  producedBy
  place
  committeeMinutes
  observations
  project {
    id
    name
    participants {
      id
      user {
        id
        name
      }
    }
  }
  actaParticipants {
    id
    item
    name
    email
    company
    charge
  }
  absents {
    id
    item
    name
    email
    company
    charge
  } 
  orderDays {
    id
    item
    subject
    responsible
  }
  committeeDevelopments {
    id
    item
    subject
    compromise
    date
    responsible    
    email
    state
    userCreated {
      id
      name
    }
  }
  actaFiles {
    id
    size
    path
    name
    type    
  }  
  consecutive
`;

export const ACTA = gql`
  query Acta($id: ID) {
    acta(id: $id) {
     ${ACTA_FIELDS}
    }
  }
`;

export const ACTAS = gql`
  query Actas($project: ID) {
    actas(project: $project) {
     ${ACTA_FIELDS}
    }
  }
`;

export const UPSERT_ACTA = gql`
  mutation UpsertActa(
    $id: ID
    $project: ID!
    $customer: String!
    $name: String!
    $date: String
    $producedBy: String
    $place: String
    $committeeMinutes: String
    $observations: String
    $actaParticipants: [ActaParticipantInput]
    $absents: [ActaParticipantInput]
    $orderDays: [OrderDayInput]
    $committeeDevelopments: [CommitteeDevelopmentInput]
    $idsResponsibles: [ResponsibleCommitteeInput]
    $actAttachments: [Upload]
  ) {
    upsertActa(
      id: $id
      project: $project
      customer: $customer
      name: $name
      date: $date
      producedBy: $producedBy
      place: $place
      committeeMinutes: $committeeMinutes
      observations: $observations
      actaParticipants: $actaParticipants
      absents: $absents
      orderDays: $orderDays
      committeeDevelopments: $committeeDevelopments
      idsResponsibles: $idsResponsibles
      actAttachments: $actAttachments
    ) {
      ${response}
    }
  }
`;

export const SEND_PDF_ACTA = gql`
mutation SendPdfEmail(
  $templateHtml: String, 
  $users: [String], 
  $actaId: String, 
  $projectName: String, 
  $projectId: String
  $consecutive: Int) {
  sendPdfEmail(
    templateHtml: $templateHtml, 
    users: $users, 
    actaId: $actaId, 
    projectName: $projectName, 
    projectId: $projectId
    consecutive: $consecutive) {
    ${response}
  }
}
`;
