import gql from 'graphql-tag';

export const COUNTRY_NAME = gql`
  query Country($id: ID) {
    country(id: $id) {
      id
      name
    }
  }
`;
