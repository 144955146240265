import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import { ChangeState } from './ChangeState';

const buttonProps = {
  size: 'large',
  type: 'primary',
};

const ButtonsProject = ({
  id,
  history,
  buttons,
  hiddenSave,
  disabledSave,
  hiddenState,
  template,
  responsible,
  onCancel,
  textCancel,
  loadingSave,
  messageValidations,
  origin,
}) => {
  const { t } = useTranslation();
  const cancel = () => {
    history.push(
      `/${
        template
          ? 'templates'
          : origin === 'dashboard'
          ? 'dashboard'
          : 'projects'
      }`
    );
  };
  return (
    <Row gutter={8}>
      <Col className="gutter-row" span={24}>
        <div className="custom-buttons-container">
          <Button size="large" onClick={onCancel || cancel}>
            {t(textCancel || 'Cancel')}
          </Button>{' '}
          {!hiddenSave && (
            <Button
              htmlType="submit"
              {...buttonProps}
              disabled={disabledSave || loadingSave}
              loading={loadingSave}
            >
              {t('Save')}
            </Button>
          )}{' '}
          {buttons &&
            !hiddenState &&
            // responsible &&
            buttons
              .sort((a, b) => (a.sense > b.sense ? -1 : 1))
              .map(
                (x, index) =>
                  x.transitions &&
                  x.transitions.length > 0 && (
                    <ChangeState
                      key={index}
                      id={id}
                      transitions={x.transitions}
                      history={history}
                      messageValidations={messageValidations}
                    >
                      {x.sense === 'Adelante' ? (
                        <Fragment>
                          {x.name}
                          <Icon
                            type="arrow-right"
                            className="custom-icon-button"
                          />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Icon
                            type="arrow-left"
                            className="custom-icon-button"
                          />
                          {x.name}
                        </Fragment>
                      )}
                    </ChangeState>
                  )
              )}
        </div>
      </Col>
    </Row>
  );
};

ButtonsProject.propTypes = {
  history: PropTypes.object,
  buttons: PropTypes.any,
  id: PropTypes.string,
  hiddenSave: PropTypes.bool,
  disabledSave: PropTypes.bool,
  hiddenState: PropTypes.bool,
  template: PropTypes.bool,
  responsible: PropTypes.bool,
  onCancel: PropTypes.func,
  textCancel: PropTypes.string,
  project: PropTypes.any,
  loadingSave: PropTypes.bool,
  messageValidations: PropTypes.func,
  origin: PropTypes.string,
};

export default ButtonsProject;
