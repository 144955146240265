import moment from 'moment';

const useTimeReport = ({ form }) => {
  const actualDate = moment().startOf('day');

  const changeTime = (time, option) => {
    let billedTime;
    let notBilledTime;

    const getCurrent = () => (time || '00:00').split(':');
    const getOther = other => {
      if (other && typeof other === 'object') {
        return other.format('HH:mm').split(':');
      }

      return ['00', '00'];
    };

    debugger;

    switch (option) {
      case 'billedTime':
        billedTime = getCurrent();
        notBilledTime = getOther(form.getFieldValue('notBilledTime'));
        break;
      case 'notBilledTime':
      default:
        notBilledTime = getCurrent();
        billedTime = getOther(form.getFieldValue('billedTime'));
        break;
    }

    const result = moment(actualDate)
      .add(parseInt(billedTime[0]) + parseInt(notBilledTime[0]), 'hours')
      .add(parseInt(billedTime[1]) + parseInt(notBilledTime[1]), 'minutes');

    form.setFieldsValue({ time: result });
  };

  const disabledHours = () => [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

  return {
    actualDate,
    changeTime,
    disabledHours,
  };
};

export default useTimeReport;
