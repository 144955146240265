import React from 'react';
import { Layout, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import MenuHeader from './MenuHeader';
import TimeHeader from './TimeHeader';

const Header = ({ pathname, isCollapsed, menus }) => (
  <Layout.Header className="custom-header">
    <Row type="flex" align="middle" justify="space-between">
      <Col span={24} order={2} className="custom-align-right">
        <Row type="flex" align="middle" justify="end">
          <TimeHeader />
          {/* <Icon */}
          {/*  type="bell" */}
          {/*  onClick={() => {}} */}
          {/*  className="custom-notification-bell" */}
          {/* /> */}
          <MenuHeader
            menus={menus}
            isCollapse={isCollapsed}
            pathname={pathname}
          />
        </Row>
      </Col>
    </Row>
  </Layout.Header>
);

Header.propTypes = {
  pathname: PropTypes.string,
  isCollapsed: PropTypes.bool,
  menus: PropTypes.array,
};

Header.defaultProps = {
  isCollapsed: false,
};

export default Header;
