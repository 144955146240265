import React, { useState, Suspense, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { GUEST, LOGGED, URL_WITHOUT_LAYOUT } from '../../config/constants';
import routes from '../../config/routes';
import {
  MenuPrimary,
  Logo,
  Header,
  HeaderContent,
} from '../../components/Layout';
import { useAuthenticated } from '../../components/Auth';
import { WrapperCenter, ChangeTheme } from '../../components/Shared';

const { Footer, Content, Sider } = Layout;

const redirection = (isAuthenticated, isActive) =>
  isAuthenticated && isActive
    ? '/dashboard'
    : isAuthenticated && !isActive
    ? `${URL_WITHOUT_LAYOUT}/resetPassword/activate`
    : `${URL_WITHOUT_LAYOUT}/login`;

const filterRoutes = (withLayout, menus) =>
  routes.filter(
    x =>
      x.withLayout === withLayout &&
      ((withLayout &&
        ((menus || []).map(m => m.name).includes(x.key) || x.key === '*')) ||
        true)
  );

const getUrl = path => {
  let url = '';
  const arrayRoute = path.split('/');
  const { length } = arrayRoute;

  if (length > 1) {
    let i = 1;
    while (i < length) {
      url += arrayRoute[i];
      i += 1;
    }
  }

  return url;
};

const foundMenu = pathname => {
  const route = routes.find(({ path, key }) => {
    if (path) {
      return getUrl(path) === getUrl(pathname) && key !== '*';
    }

    return false;
  });
  return (route && route.key) || '';
};

const Routes = ({
  withLayout = true,
  title,
  breadcrumb,
  setTitle = () => {},
  setBreadcrumb = () => {},
}) => {
  const {
    isAuthenticated,
    isActive,
    menus,
    roles,
    currentUser,
  } = useAuthenticated();
  const redirectTo = redirection(isAuthenticated, isActive);
  const [changeColor, setChangeColor] = useState(false);

  return (
    <Suspense
      fallback={
        <WrapperCenter>
          <Spin size="large" className="custom-layout-spin" />
        </WrapperCenter>
      }
    >
      {isAuthenticated && <ChangeTheme setChangeColor={setChangeColor} />}

      {changeColor || !isAuthenticated ? (
        <Switch>
          {filterRoutes(withLayout, menus).map(route => {
            const havePermissions =
              route.when === undefined ||
              route.when === null ||
              (!isAuthenticated && route.when === GUEST) ||
              (isAuthenticated && route.when === LOGGED);

            return (
              <Route
                key={route.index}
                exact
                path={route.path}
                render={props =>
                  havePermissions ? (
                    React.createElement(
                      route.component,
                      {
                        ...props,
                        title,
                        breadcrumb,
                        setBreadcrumb,
                        setTitle,
                        menu:
                          (menus &&
                            menus.length > 0 &&
                            menus.find(x => x.name === route.key)) ||
                          [],
                        roles: roles || [],
                        currentUser:
                          typeof currentUser === 'string'
                            ? JSON.parse(currentUser)
                            : currentUser,
                      },
                      null
                    )
                  ) : (
                    <Redirect to={redirectTo} />
                  )
                }
              />
            );
          })}
        </Switch>
      ) : (
        <WrapperCenter>
          <Spin size="large" className="custom-layout-spin" />
        </WrapperCenter>
      )}
    </Suspense>
  );
};

Routes.propTypes = {
  withLayout: PropTypes.bool,
  title: PropTypes.string,
  breadcrumb: PropTypes.string,
  setTitle: PropTypes.func,
  setBreadcrumb: PropTypes.func,
};

const Document = withRouter(
  ({
    location,
    children,
    menus,
    title,
    breadcrumb,
    logo,
    logoExpanded,
    roles,
  }) => {
    const [typeLogo, setTypeLogo] = useState('large');
    const { pathname } = location;

    const onScroll = useCallback(() => {
      if (typeLogo !== 'short' && window.scrollY > 64) {
        setTypeLogo('short');
        return;
      }

      if (typeLogo !== 'large' && window.scrollY <= 64) {
        setTypeLogo('large');
      }
    }, [typeLogo]);

    useEffect(() => {
      window.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }, [onScroll]);

    return (
      <Layout className="custom-layout">
        <Sider collapsed className="custom-layout-sider">
          <Logo type={typeLogo} logo={logo} logoExpanded={logoExpanded} />
          <MenuPrimary
            menus={menus}
            pathname={pathname && foundMenu(pathname)}
          />
        </Sider>
        <Layout className="custom-content-layout">
          <Header menus={menus} pathname={pathname} />
          <HeaderContent title={title} breadcrumb={breadcrumb} />
          <Content className="custom-layout-content">{children}</Content>
          <Footer />
        </Layout>
      </Layout>
    );
  }
);

Document.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string,
  breadcrumb: PropTypes.string,
  roles: PropTypes.array,
  menus: PropTypes.array,
  children: PropTypes.any,
};

const Root = () => {
  const {
    isAuthenticated,
    isActive,
    menus,
    roles,
    logo,
    logoExpanded,
  } = useAuthenticated();
  const [title, setTitle] = useState(undefined);
  const [breadcrumb, setBreadcrumb] = useState(undefined);

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={redirection(isAuthenticated, isActive)} />}
      />
      <Route
        path={URL_WITHOUT_LAYOUT}
        render={() => <Routes withLayout={false} />}
      />
      <Document
        menus={menus}
        roles={roles}
        title={title}
        breadcrumb={breadcrumb}
        logo={logo}
        logoExpanded={logoExpanded}
      >
        <Routes
          withLauout
          title={title}
          breadcrumb={breadcrumb}
          setTitle={setTitle}
          setBreadcrumb={setBreadcrumb}
        />
      </Document>
    </Switch>
  );
};

export default Root;
