import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DynamicAntdTheme, {
  getThemeColor,
  changeAntdTheme,
} from 'dynamic-antd-theme';
// import { PRIMARY_COLOR } from '../../config/constants';

const CustomTheme = ({ colorBase, selectedColor, change, setChangeColor }) => {
  const [initColor, setInitColor] = useState(true);

  const colorChange = colorp => ({
    '@base-color': `${colorp}`,
    '@primary-color': '@base-color',
    '@secondary-color': '#000000',
    '@text-color': 'fade(#000, 65%)',
    '@text-color-secondary': '#000000',
    '@heading-color': 'fade(#000, 85%)',
    '@layout-body-background': '#ebeef0',
    '@btn-primary-bg': '@base-color',
    '@layout-header-background': '@base-color',
    '@layout-sider-background': '#ffffff',
    '@border-color-base': 'hsv(0, 0, 75%)',
    '@tabs-active-color': '#ffffff',
    '@tabs-ink-bar-color': 'transparent',
    '@tabs-hover-color': 'darken(#ffffff, 30%)',
    '@tabs-highlight-color': 'darken(#ffffff, 30%)',
    '@project-tab-hover': 'lighten(@base-color, 15%)',
  });

  const themeChangeCallback = color => {
    let colorApply = color;
    if (selectedColor && initColor) {
      colorApply = selectedColor ? colorBase : color;
      setInitColor(false);
    }
    if (change) colorApply = colorBase;
    changeAntdTheme(getThemeColor(colorApply));
    window.less.modifyVars(colorChange(colorApply));
    if (selectedColor) selectedColor(colorApply);
  };

  useEffect(() => {
    changeAntdTheme(getThemeColor(colorBase));
    window.less.modifyVars(colorChange(colorBase));
    localStorage.setItem('custom-theme', colorBase);
    if (setChangeColor) setChangeColor(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {colorBase && (
        <DynamicAntdTheme
          style={{ display: selectedColor ? 'block' : 'none' }}
          primaryColor={colorBase}
          themeChangeCallback={themeChangeCallback}
          storageName="custom-theme"
        />
      )}
    </Fragment>
  );
};

CustomTheme.propTypes = {
  selectedColor: PropTypes.func,
  colorBase: PropTypes.string,
  change: PropTypes.bool,
  setChangeColor: PropTypes.func,
};

export default CustomTheme;
