import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { show } from '../../utils/message';
import { UPSERT_TIME_RECORD } from '../../graphql';

const initialDetail = { visible: false, detail: undefined };

const useTimeDetail = ({ form }) => {
  const [detail, setDetail] = useState(initialDetail);
  const [upsert, setUpsert] = useState(undefined);

  const { t } = useTranslation();

  const handleEdit = record => setDetail({ visible: true, record });

  const columns = {
    activity: 'timeRecord.activity',
    project: 'timeRecord.project',
    billedTime: 'timeRecord.billedTime',
    notBilledTime: 'timeRecord.notBilledTime',
    time: 'timeRecord.time',
    date: 'timeRecord.date',
    description: 'timeRecord.description',
  };

  const renders = [
    {
      key: 'activity',
      render: (text, { activity }) => activity && activity.name,
    },
    {
      key: 'project',
      render: (text, { activity }) =>
        activity && activity.project && activity.project.name,
    },
    {
      key: 'billedTime',
      width: 100,
      render: (text, { billedTime }) => billedTime || '00:00',
    },
    {
      key: 'notBilledTime',
      width: 100,
      render: (text, { notBilledTime }) => notBilledTime || '00:00',
    },
    {
      key: 'time',
      width: 100,
      render: (text, { hours, minutes }) =>
        `${hours > 9 ? hours : `0${hours}`}:${
          minutes > 9 ? minutes : `0${minutes}`
        }`,
    },
    {
      key: 'date',
      render: (text, { date }) => moment(date).format('DD-MM-YYYY'),
    },
  ];

  const onCancel = () => setDetail(initialDetail);
  const onOk = () =>
    form.validateFields(async (err, values) => {
      if (!err) {
        const { time, date, billedTime, notBilledTime } = values;

        const hours = time.hours();
        const minutes = time.minutes();

        if (hours === 0 && minutes === 0) {
          show({ message: t('timeRecord.messageTime'), type: 'warning' });
          return;
        }
        const { upsertTimeRecord } = (await upsert({
          variables: {
            ...values,
            id: detail && detail.record && detail.record.id,
            date,
            hours,
            minutes,
            type: 'individual',
            billedTime: billedTime ? billedTime.format('HH:mm') : undefined,
            notBilledTime: notBilledTime
              ? notBilledTime.format('HH:mm')
              : undefined,
          },
        })).data;

        if (upsertTimeRecord.result) {
          show({
            message: upsertTimeRecord.message,
            type: upsertTimeRecord.result ? 'success' : 'warning',
          });

          onCancel();
        }
      }
    });

  const tasks = [
    {
      setFunction: setUpsert,
      state: upsert,
      mutation: {
        mutation: UPSERT_TIME_RECORD,
      },
    },
  ];

  return {
    columns,
    detail,
    handleEdit,
    onCancel,
    onOk,
    renders,
    tasks,
  };
};

export default useTimeDetail;
