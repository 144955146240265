import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ text }) => (
  <div className="custom-title-container">
    <h1 className="custom-title">{text}</h1>
  </div>
);

Title.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Title;
