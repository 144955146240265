import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { IS_LOGGED_IN } from '../graphql';

const store = new InMemoryCache();

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API || 'http://localhost:4000/',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ networkError = {} }) => {
  if (networkError.statusCode === 401) {
    console.log('Error 401');
  }

  // let key;
  // let translation;
  // if (graphQLErrors) {
  //   graphQLErrors.forEach(({ message }) => {
  //     key = `graphQLError.${message.replace('Error: ', '')}`;
  //     translation = t(key);
  //     show({
  //       type: 'error',
  //       message: translation === key ? message : translation,
  //     });
  //   });
  // }
  // if (networkError) {
  //   show({
  //     type: 'warning',
  //     message: networkError,
  //   });
  // }
});

const setAuth = (auth, cache) => {
  const { roles, menus, user, token, logo, logoExpanded, photo } = auth;
  const userString = JSON.stringify(user);

  localStorage.setItem('token', token);
  localStorage.setItem('active', (user && user.active) || false);
  localStorage.setItem('currentUser', userString);
  localStorage.setItem('roles', JSON.stringify(roles));
  localStorage.setItem('menus', JSON.stringify(menus));
  localStorage.setItem('logo', logo);
  localStorage.setItem('logoExpanded', logoExpanded);
  localStorage.setItem('photo', photo);

  cache.writeData({
    data: {
      userLogged: {
        isActive: (auth.user && auth.user.active) || false,
        isLoggedIn: auth.token !== 'null' && !!auth.token,
        currentUser: userString,
        roles,
        menus,
        logo,
        logoExpanded,
        photo,
        __typename: 'UserLogged',
      },
    },
  });
  return null;
};

const resolvers = {
  Query: {
    userLogged() {
      return {
        isLoggedIn:
          localStorage.getItem('token') !== 'null' &&
          !!localStorage.getItem('token'),
        isActive: localStorage.getItem('active') !== 'false',
        currentUser: JSON.parse(localStorage.getItem('currentUser')),
        roles: JSON.parse(localStorage.getItem('roles')),
        menus: JSON.parse(localStorage.getItem('menus')),
        logo: localStorage.getItem('logo'),
        logoExpanded: localStorage.getItem('logoExpanded'),
        photo: localStorage.getItem('photo'),
      };
    },
  },
  Mutation: {
    login({ login }, args, { cache }) {
      setAuth(login, cache);
    },
    logout(root, args, { cache }) {
      localStorage.removeItem('token');
      localStorage.removeItem('active');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('roles');
      localStorage.removeItem('menus');
      localStorage.removeItem('logo');
      localStorage.removeItem('logoExpanded');
      localStorage.removeItem('photo');
      localStorage.removeItem('custom-theme');
      localStorage.removeItem('permissions');

      cache.writeData({
        data: {
          userLogged: {
            isLoggedIn: false,
            isActive: false,
            currentUser: null,
            roles: [],
            menu: [],
            logo: undefined,
            logoExpanded: undefined,
            photo: undefined,
            __typename: 'UserLogged',
          },
        },
      });
      return null;
    },
  },
};

const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache: store,
  resolvers,
});

store.writeData({
  data: {
    userLogged: {
      isLoggedIn:
        localStorage.getItem('token') !== 'null' &&
        !!localStorage.getItem('token'),
      isActive: localStorage.getItem('active') !== 'false',
      currentUser: localStorage.getItem('currentUser'),
      roles: JSON.parse(localStorage.getItem('roles')),
      menus: JSON.parse(localStorage.getItem('menus')),
      logo: localStorage.getItem('logo'),
      logoExpanded: localStorage.getItem('logoExpanded'),
      photo: localStorage.getItem('photo'),
      __typename: 'UserLogged',
    },
  },
});

export const observableLoggedIn = client.watchQuery({ query: IS_LOGGED_IN });
export default client;
