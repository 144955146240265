import gql from 'graphql-tag';

export const TABS = gql`
  query Tabs($userId: ID, $projectId: ID, $type: ID) {
    tabs(userId: $userId, projectId: $projectId, type: $type) {
      id
      name
      order
      interactions {
        id
        name
      }
    }
  }
`;

export const TABS_LIST = gql`
  query TabsList {
    tabsList {
      id
      name
      order
    }
  }
`;
