import {
  createMenu,
  createComponent,
  createMenuParent,
} from '../utils/general';
import { LOGGED } from './constants';
import HeaderUser from '../components/Layout/HeaderUser';

const securityMenu = [
  createMenu('/users', 'menu.users', 'team', LOGGED, 'users'),
  createMenu('/roles', 'menu.roles', 'lock', LOGGED, 'roles'),
  createMenu('/companies', 'menu.companies', 'shop', LOGGED, 'companies'),
];

const administrationMenu = [
  createMenu('/countries', 'menu.countries', 'global', LOGGED, 'countries'),
  createMenu('/provinces', 'menu.provinces', 'global', LOGGED, 'provinces'),
  createMenu('/cities', 'menu.cities', 'global', LOGGED, 'cities'),
];

const projectMenu = [
  createMenu('/customers', 'menu.customers', 'user', LOGGED, 'customers'),
  createMenu('/projects', 'menu.projects', 'build', LOGGED, 'projects'),
];

const flowMenu = [
  createMenu(
    '/projectTypes',
    'menu.projectTypes',
    'switcher',
    LOGGED,
    'projectTypes'
  ),
  createMenu('/charges', 'menu.charges', 'build', LOGGED, 'charges'),
  createMenu(
    '/recordTypes',
    'menu.recordTypes',
    'switcher',
    LOGGED,
    'recordTypes'
  ),
  createMenu(
    '/followupTypes',
    'menu.followupTypes',
    'container',
    LOGGED,
    'followupTypes'
  ),
  createMenu('/templates', 'menu.templates', 'container', LOGGED, 'templates'),
  createMenu('/stages', 'menu.stages', 'switcher', LOGGED, 'stages'),
  createMenu('/flows', 'menu.flows', 'interaction', LOGGED, 'flows'),
  createMenu(
    '/notifications',
    'menu.notifications',
    'notification',
    LOGGED,
    'notifications'
  ),
];

const reportMenu = [
  createMenu(
    '/timeReport',
    'menu.timeReport',
    'clock-circle',
    LOGGED,
    'timeReport'
  ),
  createMenu(
    '/report/projects',
    'menu.report.project',
    'audit',
    LOGGED,
    'report.project'
  ),
  createMenu(
    '/report/time',
    'menu.report.time',
    'reconciliation',
    LOGGED,
    'report.time'
  ),
  createMenu(
    '/report/detailedProject',
    'Reporte detallado',
    'container',
    LOGGED,
    'detailedProjectReport'
  ),
];

const BillingMenu = [
  createMenu('/billings', 'Facturación', 'dollar', LOGGED, 'billings'),
];

const profileMenu = [
  createMenu('/profile', 'menu.profile', 'user', LOGGED, 'profile'),
];

const diagnosisMenu = [
  createMenu(
    '/qualifications',
    'menu.qualifications',
    'form',
    LOGGED,
    'qualifications'
  ),
  createMenu(
    '/diagnosticses',
    'menu.diagnosticses',
    'container',
    LOGGED,
    'diagnosticses'
  ),
  createMenu(
    '/perfomDiagnostics',
    'menu.perfomDiagnostics',
    'profile',
    LOGGED,
    'perfomDiagnostics'
  ),
  createMenu(
    '/myDiagnosticses',
    'menu.myDiagnosticses',
    'project',
    LOGGED,
    'myDiagnosticses'
  ),
];

const menus = {
  primary: [
    createMenu('/dashboard', 'menu.dashboard', 'home', LOGGED, 'dashboard'),
    createMenuParent('menu.profile', 'solution', LOGGED, '*', profileMenu),
    createMenuParent('menu.security', 'lock', LOGGED, '*', securityMenu),
    createMenuParent(
      'menu.administration',
      'setting',
      LOGGED,
      '*',
      administrationMenu
    ),
    createMenuParent('menu.flow', 'apartment', LOGGED, '*', flowMenu),
    createMenuParent('menu.project', 'project', LOGGED, '*', projectMenu),
    createMenuParent('menu.report', 'audit', LOGGED, '*', reportMenu),
    createMenuParent(
      'menu.diagnosis',
      'file-protect',
      LOGGED,
      '*',
      diagnosisMenu
    ),
    createMenuParent('menu.billings', 'file-add', LOGGED, '*', BillingMenu),
  ],
  header: [createComponent(() => HeaderUser, LOGGED, '*')],
};

export default menus;
