import gql from 'graphql-tag';

export const NOTIFICATION_NAME = gql`
  query Notification($id: ID) {
    notification(id: $id) {
      id
      name
    }
  }
`;

export const NOTIFICATIONS = gql`
  query Notifications {
    notifications {
      id
      name
    }
  }
`;
