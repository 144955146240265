import gql from 'graphql-tag';

export const RECORD_TYPE_NAME = gql`
  query RecordType($id: ID) {
    recordType(id: $id) {
      id
      name
    }
  }
`;

export const RECORDTYPES = gql`
  query RecordTypes {
    recordTypes {
      id
      name
    }
  }
`;

export const RECORDTYPES_BINNACLE = gql`
  query RecordTypeBinnacle {
    recordTypeBinnacle {
      id
      name
    }
  }
`;

export const RECORD_TYPE_ATTACHMENT = gql`
  query recordTypeAttachment(
    $projectType: ID!
    $project: ID
    $stateId: ID
    $isTemplate: Boolean
    $userId: ID
  ) {
    recordTypeAttachment(
      projectType: $projectType
      isTemplate: $isTemplate
      userId: $userId
    ) {
      id
      name
      documentsCount(project: $project, stateId: $stateId)
    }
  }
`;
