import gql from 'graphql-tag';
import { response } from '../utils/common';

export const BINNACLE_FIELDS = `
  id
  date
  startTime
  endTime
  binnacleType {
    id
    name    
  }
  description
  author {
    id
    name
  }
`;

export const BINNACLE = gql`
  query Binnacle($id: ID!) {
    binnacle(id: $id) {
     ${BINNACLE_FIELDS}
    }
  }
`;

export const BINNACLES = gql`
  query Binnacles($projectId: ID) {
    binnacles(projectId: $projectId) {
     ${BINNACLE_FIELDS}
    }
  }
`;

export const UPSERT_BINNACLE = gql`
    mutation UpsertBinnacle(
        $id: ID
        $author: ID
        $date: String!
        $startTime: String!
        $endTime: String!
        $activity: String  
        $binnacleType: ID
        $project: ID!  
        $description: String         
    ) {
      upsertBinnacle(
          id: $id
          author: $author
          date: $date
          startTime: $startTime
          endTime: $endTime
          activity: $activity
          binnacleType: $binnacleType
          project: $project
          description: $description
      ) {
          ${response}
      }
    }
  `;
