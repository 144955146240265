import gql from 'graphql-tag';

export const SENSES = gql`
  query Senses {
    senses {
      id
      name
    }
  }
`;
