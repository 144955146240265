import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Modal, Select, Spin } from 'antd';
import { ApolloTasks } from '../../Shared';
import {
  UPDATE_PROJECT_STATE,
  USERS_PARTICIPANTS,
  STATE_RESPONSIBLE,
  USER_LIST,
} from '../../../graphql';
import { projectQuery } from '../../../config/cruds/project';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const initialValue = record => {
  const users = [];
  for (const responsible of record) {
    for (const participant of responsible.charge.participants) {
      users.push(participant.user.id);
    }
  }
  return new Set(users);
};

const updateStateCache = (cache, { data }) => {
  const { dataResult } = data.updateProjectState;
  const { project } = cache.readQuery({
    query: projectQuery,
    variables: { id: dataResult.id },
  });
  project.state.id = dataResult.state.id;
  project.state.name = dataResult.state.name;
  cache.writeQuery({
    query: projectQuery,
    variables: { id: dataResult.id },
    data: { project },
  });
};

const ChangeStateForm = ({
  visibility,
  transitions,
  projectId,
  onCancel,
  onSubmit,
  form,
  loading,
}) => {
  const [status, setStatus] = useState(undefined);
  const [nextState, setNextState] = useState(undefined);
  const [projectState, setProjectState] = useState(undefined);
  const [users, setUsers] = useState(undefined);
  const [visualizer, setVisualizer] = useState(undefined);

  const { t } = useTranslation();

  const submit = e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        onSubmit(values, projectState);
      }
    });
  };

  const tasks = [
    {
      setFunction: setNextState,
      query: {
        query: STATE_RESPONSIBLE,
        accessData: 'state',
        props: {
          fetchPolicy: 'network-only',
          variables: { id: status || '', projectId },
        },
      },
    },
    {
      setFunction: setProjectState,
      state: projectState,
      mutation: {
        mutation: UPDATE_PROJECT_STATE,
        props: {
          update: updateStateCache,
        },
      },
    },
    {
      setFunction: setUsers,
      query: {
        query: USERS_PARTICIPANTS,
        accessData: 'usersParticipants',
        props: {
          fetchPolicy: 'network-only',
          variables: { projectId },
        },
      },
    },
    {
      setFunction: setVisualizer,
      state: visualizer,
      query: {
        query: USER_LIST,
        accessData: 'users',
        props: {
          fetchPolicy: 'network-only',
          variables: {},
        },
      },
    },
  ];

  return (
    <Modal
      visible={visibility}
      title={t('project.updateState')}
      okText={t('project.updateStateButton')}
      cancelText={t('project.cancel')}
      onCancel={onCancel}
      onOk={submit}
    >
      <ApolloTasks tasks={tasks}>
        <Form layout="horizontal" autoComplete="off">
          {loading && <Spin />}
          <Form.Item label={t('project.nextState')} {...formItemLayout}>
            {form.getFieldDecorator('nextState', {
              initialValue: '',
              rules: [{ required: true, message: t('project.stateRequired') }],
            })(
              <Select mode="default" onChange={value => setStatus(value)}>
                {transitions.map(x => (
                  <Select.Option key={x.end.id} value={x.end.id}>
                    {x.end.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {nextState && users ? (
            <Form.Item label={t('project.responsible')} {...formItemLayout}>
              {form.getFieldDecorator('responsible', {
                initialValue: Array.from(initialValue(nextState.responsible)),
              })(
                <Select
                  mode="multiple"
                  disabled={
                    !(
                      nextState &&
                      nextState.responsibleType &&
                      nextState.responsibleType.name ===
                        'Seleccionado durante el flujo'
                    )
                  }
                >
                  {users.map(x => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          ) : (
            <Fragment />
          )}
          {nextState && visualizer ? (
            <Form.Item label={t('project.visualizers')} {...formItemLayout}>
              {form.getFieldDecorator('visualizers', {
                initialValue: Array.from(
                  new Set(nextState.visualizers.map(x => x.user.id))
                ),
              })(
                <Select mode="multiple" placeholder={t('project.visualizers')}>
                  {visualizer.map(x => (
                    <Select.Option key={x.id} value={x.id}>
                      {x.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          ) : (
            <Fragment />
          )}
        </Form>
      </ApolloTasks>
    </Modal>
  );
};

ChangeStateForm.propTypes = {
  visibility: PropTypes.bool,
  transitions: PropTypes.array,
  projectId: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  form: PropTypes.object,
  loading: PropTypes.bool,
};

export default Form.create({})(ChangeStateForm);
