import gql from 'graphql-tag';
import { response } from '../utils/common';

export const ACTIVITY_FIELDS = `
  id
  name
  stage {
    id
    name
  }
  responsible {
    id
    user {
      id
      name
      photo {
        id
        url
      }
    }
  }
  files {
    id
    name
    path
    size
  }
  start
  end
  estimate
  duration
  executed
  description
  createdAt
  updatedAt
`;

export const UPSERT_ACTIVITY = gql`
  mutation UpsertActivity(
    $id: ID
    $project: ID!
    $name: String!
    $stage: ID
    $end: String
    $start: String
    $description: String
    $duration: Float
    $estimate: Float
    $executed: Float
    $responsible: [ID]
    $files: [Upload]
  ) {
    upsertActivity(
      id: $id
      project: $project
      name: $name
      stage: $stage
      end: $end
      start: $start
      description: $description
      duration: $duration
      estimate: $estimate
      executed: $executed
      responsible: $responsible
      files: $files
    ) {
      ${response}
      dataResult {
        ${ACTIVITY_FIELDS}
      }
    }
  }
`;

export const UPDATE_ACTIVITY_STAGE = gql`
  mutation UpdateActivityStage($id: ID!, $stage: ID!) {
    updateActivityStage(id: $id, stage: $stage) {
      id
      name
      stage {
        id
        name
      }
      updatedAt
      createdAt
    }
  }
`;

export const DELETE_ACTIVITY = gql`
  mutation DeleteActivity($id: ID!) {
    deleteActivity(id: $id) {
      ${response}
    }
  }
`;

export const ACTIVITIES_STATE = gql`
  query ActivitiesTemplate($flowId: ID, $stateId: ID) {
    activitiesTemplate(flowId: $flowId, stateId: $stateId) {
      id
      name
    }
  }
`;

export const ACTIVITIES = gql`
  query Activities($projectId: ID) {
    activities(projectId: $projectId) {
      id
      name
    }
  }
`;

export const UPSERT_ACTIVITY_TEMPLATE = gql`
  mutation UpsertActivityTemplate(
    $id: ID
    $project: ID
    $name: String
    $description: String
    $estimate: Float
  ) {
    upsertActivityTemplate(
      id: $id
      project: $project
      name: $name
      description: $description
      estimate: $estimate
    ) {
      ${response}
    }
  }
`;

export const DELETE_ACTIVITY_TEMPLATE = gql`
  mutation DeleteActivityTemplate($id: ID) {
    deleteActivityTemplate(id: $id) {
      ${response}
    }
  }
`;

export const ACTIVITIES_BY_RESPONSIBLE = gql`
  query ActivitiesByResponsible {
    activitiesByResponsible {
      id
      name
    }
  }
`;
