import gql from 'graphql-tag';
import { response } from '../../utils/common';
import { FIELDS_PROJECT } from '../../graphql';

export const projectQuery = gql`
  query Project ($id: ID!) {
    project(id: $id) {
      ${FIELDS_PROJECT}
    }
  }
`;

export const projectsQuery = (isTemplate, userId) => gql`
  query Projects {
    projects(template: ${isTemplate}, userId: "${userId}") {
      ${FIELDS_PROJECT}
    }
  }
`;

const typeProjects = gql`
  query ProjectTypes {
    projectTypes {
      id
      name
    }
  }
`;

const typeProjectTemplate = gql`
  query ProjectTypes {
    projectTypeTemplate {
      id
      name
    }
  }
`;

const project = (isTemplate, typeProject, projectId, userId) => ({
  keyName: 'id',
  getList: {
    accessData: 'projects',
    query: projectsQuery(isTemplate, userId),
  },
  getByKey: {
    accessData: 'project',
    query: projectQuery,
  },
  upsert: {
    accessData: 'upsertProject',
    query: gql`
      mutation UpsertProject(
        $id: ID
        $name: String
        $description: String
        $isTemplate: Boolean!
				$type: ID!
        $origin: ID
        $flow: ID
        $stageVersion: ID
        $start: String
        $end: String
        $realEnd: String
        $plannedAdvance: Float
        $realAdvance: Float
        $billable: Boolean
        $budget: Float
        $cost: Float
        $sell: Float
        $phases: [ProjectInput]
      ) {
        upsertProject(
          id: $id
          name: $name
          description: $description
          isTemplate: $isTemplate
          type: $type
          origin: $origin
          flow: $flow
          stageVersion: $stageVersion
          start: $start
          end: $end
          realEnd: $realEnd
          plannedAdvance: $plannedAdvance
          realAdvance: $realAdvance
          billable: $billable
          budget: $budget
          cost: $cost
          sell: $sell
          phases: $phases
        ) {
            ${response}
        }
      }
    `,
  },
  delete: {
    accessData: 'deleteProject',
    query: gql`
      mutation DeleteProject($id: ID!) {
        deleteProject(id: $id) {
          ${response}
        }
      }
    `,
  },
  fields: [
    {
      title: 'project.name',
      key: 'name',
      sorter: true,
      filter: true,
      type: 'string',
      hidden: [isTemplate ? 'form' : ''],
      rules: [
        { required: true, message: 'project.nameRequired' },
        { max: 500, message: 'project.nameMax' },
      ],
      props: {
        autoComplete: 'off',
      },
    },
    {
      title: 'project.templateName',
      key: 'templateName',
      sorter: true,
      filter: true,
      type: 'string',
      hidden: [!isTemplate ? 'form' : ''],
      rules: [
        { required: true, message: 'project.templateNameRequired' },
        { max: 500, message: 'project.nameMax' },
      ],
      props: {
        autoComplete: 'off',
      },
    },
    {
      title: 'project.description',
      key: 'description',
      sorter: true,
      filter: true,
      type: 'textarea',
      hidden: ['column'],
      rules: [{ max: 8000, message: 'project.descriptionMax' }],
    },
    {
      title: 'project.isTemplate',
      key: 'isTemplate',
      sorter: true,
      filter: true,
      type: 'bool',
      hidden: ['column', `form`],
      rules: [{ required: true, message: 'project.isTemplateRequired' }],
    },
    {
      title: 'project.type',
      key: 'type',
      sorter: true,
      filter: true,
      type: 'select',
      disabled: !isTemplate || (isTemplate && projectId),
      initialValue: typeProject,
      options: {},
      rules: [{ required: true, message: 'project.typeRequired' }],
      configOptions: {
        accessData:
          isTemplate && !projectId ? 'projectTypeTemplate' : 'projectTypes',
        map: item => ({ [item.id]: item.name }),
        query: isTemplate && !projectId ? typeProjectTemplate : typeProjects,
      },
      render: (text, record) => record.type.name,
    },
    {
      title: 'project.flow',
      key: 'flow',
      updateKey: render =>
        (render.flowVersion && render.flowVersion.flow.id) || null,
      sorter: true,
      filter: true,
      type: 'select',
      hidden: ['column', 'form'],
      options: {},
      rules: [{ required: !isTemplate, message: 'project.flowRequired' }],
      // initialValue: 'cjwv8u5bc01dg06774coibue6',
      configOptions: {
        accessData: 'flows',
        query: gql`
          query Flows {
            flows {
              id
              name
            }
          }
        `,
        map: item => ({ [item.id]: item.name }),
      },
    },
    {
      title: 'project.state',
      key: 'state',
      updateKey: render => (render.state && render.state.name) || null,
      sorter: true,
      filter: true,
      type: 'select',
      hidden: ['column', `${isTemplate ? 'form' : ''}`],
      disabled: true,
    },
    {
      title: 'project.origin',
      key: 'origin',
      sorter: true,
      filter: true,
      type: 'select',
      disabled: true,
      options: {},
      hidden: ['column', `form`],
      configOptions: {
        accessData: 'projects',
        map: item => ({ [item.id]: item.name }),
        query: gql`
          query Projects {
            projects {
              id
              name
            }
          }
        `,
      },
    },
    {
      title: 'project.start',
      key: 'start',
      sorter: true,
      filter: true,
      type: 'date',
      hidden: ['column', `${isTemplate ? 'form' : ''}`],
      rules: [{ required: !isTemplate, message: 'project.startRequired' }],
    },
    {
      title: 'project.end',
      key: 'end',
      sorter: true,
      filter: true,
      type: 'date',
      hidden: ['column', `${isTemplate ? 'form' : ''}`],
      rules: [{ required: !isTemplate, message: 'project.endRequired' }],
    },
    {
      title: 'project.billable',
      key: 'billable',
      sorter: true,
      filter: true,
      type: 'bool',
      hidden: ['column', `${isTemplate ? 'form' : ''}`],
      props: { checkedChildren: 'Si', unCheckedChildren: 'No' },
    },
    {
      title: 'project.cost',
      key: 'cost',
      sorter: true,
      filter: true,
      type: 'number',
      hidden: ['column', 'form'],
      dependencies: {
        fields: ['billable'],
        onChange: async dependencies => ({
          hidden: dependencies.billable ? ['column'] : ['column', 'form'],
        }),
      },
      props: {
        style: { width: '50%' },
        min: 0,
        formatter: value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        parser: value => value.replace(/\$\s?|(,*)/g, ''),
      },
    },
    {
      title: 'project.sell',
      key: 'sell',
      sorter: true,
      filter: true,
      type: 'number',
      hidden: ['column', 'form'],
      dependencies: {
        fields: ['billable'],
        onChange: async dependencies => ({
          hidden: dependencies.billable ? ['column'] : ['column', 'form'],
        }),
      },
      props: {
        style: { width: '50%' },
        min: 0,
        formatter: value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        parser: value => value.replace(/\$\s?|(,*)/g, ''),
      },
    },
    {
      title: 'project.budget',
      key: 'budget',
      sorter: true,
      filter: true,
      type: 'number',
      hidden: ['column', `${isTemplate ? 'form' : ''}`],
      rules: [{ required: !isTemplate, message: 'project.budgetRequired' }],
      props: {
        style: { width: '50%' },
        min: 0,
        formatter: value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        parser: value => value.replace(/\$\s?|(,*)/g, ''),
      },
    },
    {
      title: 'project.customer',
      key: 'customer',
      sorter: true,
      filter: true,
      type: 'select',
      hidden: [`${isTemplate ? 'form' : ''}`],
      rules: [{ required: !isTemplate, message: 'project.customerRequired' }],
      options: {},
      configOptions: {
        accessData: 'customers',
        map: item => ({ [item.id]: item.name }),
        query: gql`
          query Customers {
            customers {
              id
              name
            }
          }
        `,
      },
      render: (text, record) => record.customer.name,
    },
  ],
});

export default project;
