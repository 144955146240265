import gql from 'graphql-tag';

export const PARTICIPANTS_PROJECT = gql`
  query Participants($projectId: ID) {
    participants(projectId: $projectId) {
      id
      user {
        id
        name
      }
      charge {
        id
        name
      }
    }
  }
`;

export const PARTICIPANTS_BY_RESPONSIBLE = gql`
  query ParticipantsByResponsible {
    participantsByResponsible {
      id
      userInfo {
        id
        name
      }
    }
  }
`;
