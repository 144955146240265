import gql from 'graphql-tag';
import { response } from '../utils/common';

const timeRecordsFields = `
  id
  description
  hours
  minutes
  date
  start
  end
  billedTime
  notBilledTime
  detail {
    id
    description
    hours
    minutes
    date
    billedTime
    notBilledTime
    activity {
      id
      name
    }
    user {
      id
      name
    }
  }
  activity {
    id
    name
  }
  user {
    id
    name
  }
`;

export const TIME_RECORDS = gql`
  query TimeRecords($projectId: ID $type: String) {
    timeRecords(projectId: $projectId type: $type) {
      ${timeRecordsFields}
    }
  }
`;

export const UPSERT_TIME_RECORD = gql`
  mutation UpsertTimeRecord(
    $id: ID
    $description: String
    $hours: Int!
    $minutes: Int!
    $selectActivity: Boolean
    $date: String
    $start: String
    $end: String
    $type: String
    $activity: String
    $project: ID
    $user: ID
    $billedTime: String
    $notBilledTime: String
  ) {
    upsertTimeRecord(
      id: $id 
      description: $description 
      hours: $hours 
      minutes: $minutes 
      selectActivity: $selectActivity 
      date: $date 
      start: $start 
      end: $end 
      activity: $activity 
      project: $project
      user: $user 
      type: $type
      billedTime: $billedTime
      notBilledTime: $notBilledTime
    ) {
      ${response}
      dataResult {
        ${timeRecordsFields}
      }
    }
  }
`;

export const DELETE_TIME_RECORD = gql`
  mutation DeleteTimeRecord($id: ID) {
    deleteTimeRecord(id: $id) {
      ${response}
    }
  }
`;
