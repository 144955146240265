const chooseBehavior = ({ value, list, old }) => {
  // Seleccionaron el seleccionar todos
  if (value.find(x => x === 'all')) {
    // Si tiene seleccionados todos desmarca si no selecciona todos
    return old.length !== list.length ? 0 : 1;
  }

  // No interactúa el seleccionar todos
  return 2;
};

const handleSelectAll = ({ handler, others, field, value, list, ...rest }) => {
  let result = {};

  switch (chooseBehavior({ ...rest, value, list })) {
    case 0:
      result = {
        ...others,
        [field]: list.map(({ id }) => id),
      };
      handler(result);
      break;
    case 1:
      result = { ...others, [field]: [] };
      handler(result);
      break;
    case 2:
    default:
      result = { ...others, [field]: value };
      handler(result);
      break;
  }

  return result;
};

export default handleSelectAll;
