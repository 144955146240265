import { message as msg, Modal } from 'antd';

const showMessage = ({ type, message, duration = 2 }) => {
  switch (type) {
    case 'success':
      msg.success(message, duration);
      break;
    case 'error':
      msg.error(message, duration);
      break;
    case 'warning':
      Modal.warning({
        content: message,
        okText: 'Aceptar',
      });
      break;
    default:
      msg.info(message, duration);
      break;
  }
};

export const show = data => {
  showMessage({ ...data });
};
