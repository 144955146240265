import gql from 'graphql-tag';
import { response } from '../utils/common';

const fields = `
  id
  question
  exposition
  worth
  description
  active
  theme {
    id
    name
  }
  createdAt
  updatedAt
`;

export const QUESTION_BY_DIAGNOSTICS = gql`
  query QuestionsByDiagnostics($diagnosticsId: ID) {
    questionsByDiagnostics(diagnosticsId: $diagnosticsId) {
     ${fields}
    }
  }
`;

export const QUESTION_BY_THEME = gql`
  query QuestionsByTheme($themeId: ID) {
    questionsByTheme(themeId: $themeId) {
     ${fields}
    }
  }
`;

export const QUESTIONS = gql`
  query Questions {
    questions {
     ${fields}
    }
  }
`;

export const QUESTION = gql`
  query Question($id: ID) {
    question(id: $id) {
     ${fields}
    }
  }
`;

export const UPSERT_QUESTION = gql`
  mutation upsertQuestion(
    $id: ID!
    $theme: ID!
    $question: String!
    $exposition: String!
    $worth: Float
    $description: String
    $active: Boolean
  ) {
    upsertQuestion(
      id: $id
      theme: $theme
      question: $question
      exposition: $exposition
      worth: $worth
      description: $description
      active: $active) {
      ${response}
    }
  }
`;
