import gql from 'graphql-tag';

export const CUSTOMER_NAME = gql`
  query Customer($id: ID) {
    customer(id: $id) {
      id
      name
    }
  }
`;

export const CUSTOMER = gql`
  query Customer($id: ID) {
    customer(id: $id) {
      id
      name
      responsible
      phone
      prefix
      email
      hour
      document
      address
      documentType {
        id
        name
      }
      city {
        id
        name
        province {
          id
          name
          country {
            id
            name
          }
        }
      }
    }
  }
`;

export const CUSTOMERS = gql`
  query Customers {
    customers {
      id
      name
      responsible
      phone
      prefix
      email
      hour
      documentType {
        id
        name
      }
      document
      address
    }
  }
`;

export const CUSTOMERS_LIST = gql`
  query CustomersList {
    customersList {
      id
      name
    }
  }
`;
