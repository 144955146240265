import React from 'react';
import { Layout, Row, Col, Spin } from 'antd';
import PropTypes from 'prop-types';
import Title from '../Shared/Title';

const HeaderContent = ({ title, breadcrumb }) => (
  <Layout.Header className="custom-header-content">
    <Row type="flex" align="middle" justify="space-between">
      <Col>{title ? <Title text={title} /> : undefined}</Col>
      <Col className="custom-breadcrumb">{breadcrumb || <Spin />}</Col>
    </Row>
  </Layout.Header>
);

HeaderContent.propTypes = {
  title: PropTypes.string,
  breadcrumb: PropTypes.string,
};

export default HeaderContent;
