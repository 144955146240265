import React from 'react';
import { Avatar, Dropdown, Menu, Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import { Mutation, Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { LOGOUT, IS_LOGGED_IN } from '../../graphql';
import { URL_WITHOUT_LAYOUT } from '../../config/constants';

const MenuDrop = withRouter(({ history }) => {
  const { t } = useTranslation();
  return (
    <Mutation mutation={LOGOUT}>
      {logout => {
        const logoutAndRedirect = () => {
          logout();
          setTimeout(() => history.replace(`${URL_WITHOUT_LAYOUT}/login`), 100);
        };

        return (
          <Menu>
            <Menu.Item onClick={logoutAndRedirect}>
              <Icon type="logout" />
              {t('sideBar.logout')}
            </Menu.Item>
          </Menu>
        );
      }}
    </Mutation>
  );
});

const HeaderUser = () => (
  <Query query={IS_LOGGED_IN}>
    {({ data: { userLogged } }) => {
      // eslint-disable-next-line no-unused-vars
      const { photo, currentUser } = userLogged;
      const user = JSON.parse(currentUser);
      // photo ? { src: photo } : { icon: 'user' };
      const image =
        localStorage.getItem('photo') && localStorage.getItem('photo') !== ''
          ? { src: localStorage.getItem('photo') }
          : { icon: 'user' };

      return (
        <Dropdown overlay={() => <MenuDrop />} placement="bottomRight">
          <div className="custom-header-user">
            <Avatar {...image} />
            {user && <span style={{ paddingLeft: 8 }}>{user.name}</span>}
          </div>
        </Dropdown>
      );
    }}
  </Query>
);

HeaderUser.displayName = 'HeaderUser';

export default HeaderUser;
