import gql from 'graphql-tag';

export const INTERACTIONS = gql`
  query Interactions {
    interactions {
      id
      name
    }
  }
`;
