import React from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import WrapperCenter from './WrapperCenter';

const ApolloQuery = ({
  setFunction,
  onCompleted,
  query,
  state,
  stopQuerying,
  children,
}) => (
  <Query query={query.query} {...query.props}>
    {items => {
      if (!items.loading) {
        try {
          const { data } = items;
          if (
            (!stopQuerying ||
              (typeof state === 'object' ? state.unsetted : false)) &&
            typeof onCompleted === 'function'
          ) {
            onCompleted(data);
          } else if (!stopQuerying || state === undefined) {
            setFunction(data[query.accessData]);
          }
        } catch (e) {
          console.log(e.toString());
        }
      }

      return (
        <WrapperCenter
          loading={
            items.loading &&
            (!items.data[query.accessData] || state === undefined)
          }
          padding={0}
        >
          {children}
        </WrapperCenter>
      );
    }}
  </Query>
);

ApolloQuery.propTypes = {
  setFunction: PropTypes.func,
  onCompleted: PropTypes.func,
  stopQuerying: PropTypes.bool,
  state: PropTypes.any,
  query: PropTypes.object,
  children: PropTypes.any,
};

export default ApolloQuery;
