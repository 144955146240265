import React, { Fragment } from 'react';
import { Menu, Icon } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMenu } from './hooks';

const MenuPrimary = ({ pathname, menus, ...rest }) => {
  const resultMenu = useMenu('primary', menus);
  const { t } = useTranslation();

  return (
    <Fragment>
      <Menu mode="inline" selectedKeys={[pathname || '/']} {...rest}>
        {resultMenu.map(({ path, key, icon, title, itemsSubmenu, index }) => {
          if (path) {
            return (
              <Menu.Item key={key}>
                <Link to={path}>
                  {icon && <Icon type={icon} className="custom-menu-icon" />}
                  <span className="nav-text">{t(title)}</span>
                </Link>
              </Menu.Item>
            );
          }

          if (itemsSubmenu && itemsSubmenu.length > 0) {
            return (
              <Menu.SubMenu
                key={index}
                title={
                  <div className="custom-menu-icon-container">
                    <div className="custom-active-arrow" />
                    <span>
                      {icon && (
                        <Icon type={icon} className="custom-menu-icon" />
                      )}
                      <span>{t(title)}</span>
                    </span>
                  </div>
                }
              >
                {itemsSubmenu.map(subItem => (
                  <Menu.Item key={subItem.key} className="custom-menu-item">
                    <Link to={subItem.path}>
                      {subItem.icon && (
                        <Icon
                          type={subItem.icon}
                          className="custom-menu-icon"
                        />
                      )}
                      <span className="nav-text">{t(subItem.title)}</span>
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          }
          return null;
        })}
      </Menu>
    </Fragment>
  );
};

MenuPrimary.propTypes = {
  pathname: PropTypes.string,
  menus: PropTypes.array,
};

export default MenuPrimary;
