import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ApolloMutation from './ApolloMutation';
import ApolloQuery from './ApolloQuery';

const propTypes = {
  task: PropTypes.object,
  children: PropTypes.any,
};

const Tasks = ({ task, children }) =>
  task.query ? (
    <ApolloQuery {...task}>
      <Children task={task}>{children}</Children>
    </ApolloQuery>
  ) : task.mutation ? (
    <ApolloMutation {...task}>
      <Children task={task}>{children}</Children>
    </ApolloMutation>
  ) : (
    <Fragment>
      <Children task={task}>{children}</Children>
    </Fragment>
  );

Tasks.propTypes = propTypes;

const Children = ({ task, children }) =>
  (task && task.child && <Tasks task={task.child}>{children}</Tasks>) || (
    <Fragment>{children}</Fragment>
  );

Children.propTypes = propTypes;

const ApolloTasks = ({ tasks, children }) => {
  const list = tasks.reverse();
  let task = { ...list[0], key: tasks.indexOf(list[0]) };
  while (list.length > 1) {
    task = { ...list[1], key: tasks.indexOf(list[1]), child: task };
    list.splice(0, 1);
  }

  return <Tasks task={task}>{children}</Tasks>;
};

ApolloTasks.propTypes = {
  tasks: PropTypes.array,
  children: PropTypes.any,
};

export default ApolloTasks;
