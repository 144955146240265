import gql from 'graphql-tag';
import { response } from '../utils/common';

export const SLOPE_FIELDS = `
  id
  project {
    name
  }
  responsible {
    id
    name
  }
  compromise
  date
  state
  type
  userCreated {
    id
    name
  }
`;

export const SLOPES = gql`
  query Slopes {
    slopes {
      ${SLOPE_FIELDS}
    }
  }
`;

export const SLOPES_POJECT = project => gql`
  query Slopes {
    slopes(project: "${project}", state: "Pendiente") {
      ${SLOPE_FIELDS}
    }
  }
`;

export const SLOPE = gql`
  query Slopes($id: ID) {
    slopes(id: $id) {
      ${SLOPE_FIELDS}
    }
  }
`;

export const SLOPES_BY_PROJECT = gql`
  query Slopes($project: ID, $state: String, $date: String) {
    slopes(project: $project, state: $state, date: $date) {
      ${SLOPE_FIELDS}
    }
  }
`;

export const SLOPE_TYPE = gql`
  query SlopeType($id: ID, $type: String) {
    slopeType(id: $id, type: $type) {
      ${SLOPE_FIELDS}
    }
  }
`;

export const UPDATE_SLOPE = gql`
  mutation UpsertSlope(
    $id: ID
    $type: String!
    $state: String!
    $project: ID
    $responsible: ID
    $compromise: String
    $date: String
    $userCreated: ID
  ) {
    upsertSlope(
      id: $id 
      type: $type
      state: $state
      project: $project
      responsible: $responsible 
      compromise: $compromise 
      date: $date 
      userCreated: $userCreated) {
      ${response}
    }
  }
`;
