import { useState, useEffect, useCallback } from 'react';
import { observableLoggedIn } from '../../config/client';

export function useAuthenticated() {
  const memorizedData = useCallback(
    () => observableLoggedIn.getCurrentResult().data,
    []
  );
  const data = memorizedData();

  const [auth, setAuth] = useState({
    isAuthenticated: (data && data.userLogged.isLoggedIn) || false,
    isActive: (data && data.userLogged.isActive) || false,
    roles: (data && data.userLogged.roles) || [],
    menus: (data && data.userLogged.menus) || [],
    currentUser: (data && data.userLogged.currentUser) || undefined,
    logo: (data && data.userLogged.logo) || undefined,
    logoExpanded: (data && data.userLogged.logoExpanded) || undefined,
  });

  useEffect(() => {
    const subscribed = observableLoggedIn.subscribe({
      next: ({ data: { userLogged } }) =>
        setAuth({
          isAuthenticated: userLogged.isLoggedIn,
          isActive: userLogged.isActive,
          roles: userLogged.roles,
          menus: userLogged.menus,
          currentUser: userLogged.currentUser,
          logo: userLogged.logo,
          logoExpanded: userLogged.logoExpanded,
        }),
    });
    return () => subscribed._cleanup();
  }, []);

  return { ...(auth || { isAuthenticated: false, isActive: false }) };
}
