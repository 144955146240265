import React, { Fragment, useEffect, useState } from 'react';
import { Icon, Tooltip } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TimeForm } from '../Project/TimeRecord';
import {
  ACTIVITIES,
  PROJECTS_LIST,
  PROJECTS_STATE,
  // TIME_RECORDS,
  UPSERT_TIME_RECORD,
} from '../../graphql';
import { ApolloTasks } from '../Shared';
import { show } from '../../utils/message';
import client from '../../config/client';

const TimeHeader = () => {
  const [visible, setVisible] = useState(false);
  const [upsert, setUpsert] = useState(undefined);
  const [activities, setActivities] = useState(undefined);
  const [projects, setProjects] = useState(undefined);
  const [project, setProject] = useState(undefined);

  const { t } = useTranslation();
  const [stateProjectData, setStateProjectData] = useState();

  // const updateCache = (cache, { data }) => {
  //   const { dataResult } = data.upsertTimeRecord;

  //   cache.writeQuery({
  //     query: TIME_RECORDS,
  //     variables: { project, type: 'individual' },
  //     data: { timeRecords: dataResult },
  //   });
  // };
  const stateProject = async () => {
    try {
      const data = await client.query({
        query: PROJECTS_STATE,
        variables: {},
        fetchPolicy: 'network-only',
      });
      setStateProjectData(data.data.projectsList);
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    stateProject();
  }, []);

  const handleSubmit = form => {
    form.validateFields(async (err, values) => {
      const { time, date } = values;
      if (time.hours() === 0 && time.minutes() === 0) {
        show({ message: t('timeRecord.messageTime'), type: 'warning' });
        return;
      }
      if (
        stateProjectData.some(
          item => item.id === values.project && item.state.name !== 'Cierre'
        )
      ) {
        const { upsertTimeRecord } = (await upsert({
          variables: {
            ...values,
            date: new Date(date.format('YYYY/MM/DD')),
            hours: time.hours(),
            minutes: time.minutes(),
            project,
            type: 'individual',
            billedTime:
              (values.billedTime &&
                moment(values.billedTime).format('HH:mm')) ||
              null,
            notBilledTime:
              (values.notBilledTime &&
                moment(values.notBilledTime).format('HH:mm')) ||
              null,
          },
        })).data;

        if (upsertTimeRecord.result) {
          show({
            message: upsertTimeRecord.message,
            type: upsertTimeRecord.result ? 'success' : 'warning',
          });

          setVisible(false);
        }
      } else {
        show({
          message: 'El proyecto ya esta cerrado',
          type: 'warning',
        });
      }
    });
  };

  const handleCancel = () => setVisible(false);

  // const projectChange = value => setProject(value);
  const projectChange = async value => {
    if (!value) return;
    const data = await client.query({
      query: ACTIVITIES,
      variables: { projectId: value },
      fetchPolicy: 'network-only',
    });
    if (data && data.data && data.data.activities)
      setActivities(data.data.activities);
    setProject(value);
  };

  const tasks = [
    {
      setFunction: setProjects,
      state: projects,
      query: {
        query: PROJECTS_LIST,
        accessData: 'projectsList',
        props: { fetchPolicy: 'network-only', variables: {} },
      },
    },
    // {
    //   setFunction: setActivities,
    //   state: activities,
    //   query: {
    //     query: ACTIVITIES,
    //     accessData: 'activities',
    //     props: {
    //       fetchPolicy: 'network-only',
    //       variables: { projectId: project },
    //     },
    //   },
    // },
    {
      setFunction: setUpsert,
      state: upsert,
      mutation: {
        mutation: UPSERT_TIME_RECORD,
        props: {
          // update: updateCache,
          refetchQueries: [
            {
              query: ACTIVITIES,
              variables: { projectId: project },
              fetchPolicy: 'network-only',
            },
          ],
        },
      },
    },
  ];

  return (
    <ApolloTasks tasks={tasks}>
      <Fragment>
        <Tooltip title={t('timeHeader.recordTime')}>
          <Icon
            type="clock-circle"
            className="custom-time-header"
            onClick={() => setVisible(true)}
          />
        </Tooltip>
        <TimeForm
          project
          projects={projects}
          activities={activities}
          visible={visible}
          record={{}}
          onOk={handleSubmit}
          onCancel={handleCancel}
          projectChange={projectChange}
        />
      </Fragment>
    </ApolloTasks>
  );
};

TimeHeader.propTypes = {};

export default TimeHeader;
