import gql from 'graphql-tag';
import { response } from '../utils/common';

export const CHARGE_NAME = gql`
  query Charge($id: ID) {
    charge(id: $id) {
      id
      name
    }
  }
`;

export const CHARGES_NAME = gql`
  query ChargesName {
    charges {
      id
      name
    }
  }
`;

export const CHARGES = gql`
  query Charges {
    charges {
      id
      name
      participants {
        id
        user {
          id
          name
        }
        project {
          id
        }
        charge {
          id
        }
      }
    }
  }
`;

export const CHARGE = gql`
  query Charge($id: ID) {
    charge(id: $id) {
      id
      name
      authorizations {
        id
        tab {
          id
          name
        }
        interaction {
          id
          name
        }
      }
    }
  }
`;

export const UPSERT_CHARGE = gql`
  mutation UpsertCharge(
    $id: ID
    $name: String!
    $authorizations: [AuthorizationInput]
  ) {
    upsertCharge(id: $id, name: $name, authorizations: $authorizations) {
      ${response}
    }
  }
`;
