export const response = `
  message
	errors {
		code
		location {
			column
			line
		}
		message
		path
		extensions {
			code
			exception {
				stacktrace
			}
		}
		requestId
	}
	status
	result
`;
