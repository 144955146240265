import gql from 'graphql-tag';
import { response } from '../utils/common';

export const SERVICEORDER_FIELDS = `
  id
  number
  date
  document
  customer {
    id
    name
  }
  address
  phone
  prefix
  service
  subtotal
  iva
  ivaPercentage
  total
  paymentMethod
  duration
  observations
  body
  project {
    id
    name
  }
  orderItems {
    id
    row
    amount
    description
    unitValue
    total
  }
`;

export const SERVICEORDER = gql`
  query ServiceOrder($id: ID) {
    serviceOrder(id: $id) {
     ${SERVICEORDER_FIELDS}
    }
  }
`;

export const SERVICEORDERS = gql`
  query ServiceOrders($project: ID) {
    serviceOrders(project: $project) {
     ${SERVICEORDER_FIELDS}
    }
  }
`;

export const UPSERT_SERVICEORDER = gql`
  mutation UpsertServiceOrder(
    $id: ID
    $project: ID!    
    $number: String!
    $date: String!
    $document: String!
    $customer: String!
    $address: String!
    $phone: Float
    $service: String
    $subtotal: Float
    $iva: Float
    $ivaPercentage: Float
    $total: Float
    $paymentMethod: String
    $duration: String
    $observations: String
    $body: String
    $orderItems: [OrderItemInput]
    $prefix: Int
  ) {
    upsertServiceOrder(
      id: $id
      project: $project
      number: $number
      date: $date
      document: $document
      customer: $customer
      address: $address
      phone: $phone
      service: $service
      subtotal: $subtotal
      iva: $iva
      ivaPercentage: $ivaPercentage
      total: $total
      paymentMethod: $paymentMethod
      duration: $duration
      observations: $observations
      body: $body
      orderItems: $orderItems
      prefix: $prefix
    ) {
      ${response}
    }
  }
`;
