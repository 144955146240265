import gql from 'graphql-tag';

export const CITY_NAME = gql`
  query City($id: ID) {
    city(id: $id) {
      id
      name
    }
  }
`;
