import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon } from 'antd';

const TimeFilter = ({ handleFilter, type, options }) => (
  <Menu onClick={handleFilter} selectedKeys={[type]}>
    {options &&
      options.map(x => (
        <Menu.Item key={x.key}>
          <Icon type={x.icon} />
          {x.name}
        </Menu.Item>
      ))}
  </Menu>
);

TimeFilter.propTypes = {
  type: PropTypes.string,
  options: PropTypes.array,
  handleFilter: PropTypes.func,
};

export default TimeFilter;
