import gql from 'graphql-tag';

export const PROVINCE_NAME = gql`
  query Province($id: ID) {
    province(id: $id) {
      id
      name
    }
  }
`;
