import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import { ProviderEasyCrud } from 'react-easy-crud';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Layout from './pages/security/Layout';
import client from './config/client';
import { WrapperCenter } from './components/Shared';
import getLocalesAntd from './config/localization/antdLocale';
import * as registerServiceWorker from './registerServiceWorker';
import './styles/index.less';
import './config/localization/i18n';
import 'moment/min/locales';
import ChangeTheme from './components/Shared/ChangeTheme';
import 'react-contexify/dist/ReactContexify.min.css';

const App = () => {
  const { i18n } = useTranslation();
  moment.locale(i18n.language);

  return (
    <ConfigProvider locale={getLocalesAntd(i18n.language)}>
      <ApolloProvider client={client}>
        <ProviderEasyCrud type="graphql" client={client}>
          <ChangeTheme>
            <Router>
              <Layout />
            </Router>
          </ChangeTheme>
        </ProviderEasyCrud>
      </ApolloProvider>
    </ConfigProvider>
  );
};

const AppSuspense = () => (
  <Suspense
    fallback={
      <WrapperCenter>
        <Spin size="large" className="custom-layout-spin" />
      </WrapperCenter>
    }
  >
    <App />
  </Suspense>
);

ReactDOM.render(<AppSuspense />, document.getElementById('root'));
registerServiceWorker.register();
