import gql from 'graphql-tag';
import { response } from '../utils/common';

export const DIAGNOSTICS_NAME = gql`
  query Diagnostics($id: ID) {
    diagnostics(id: $id) {
      id
      name
    }
  }
`;

export const DIAGNOSTICS = gql`
  query Diagnostics($id: ID) {
    diagnostics(id: $id) {
      id
      name
      active
      heritage
      annualInvestment
      createdAt
      updatedAt
    }
  }
`;

export const DIAGNOSTICSES_AVAILABLE = gql`
  query DiagnosticsesAvailable {
    diagnosticsesAvailable {
      id
      name
      active
      heritage
      annualInvestment
      createdAt
      updatedAt
    }
  }
`;

export const DIAGNOSTICSES = gql`
  query Diagnosticses {
    diagnosticses {
      id
      name
      active
      heritage
      annualInvestment
      createdAt
      updatedAt
    }
  }
`;

export const UPSERT_DIAGNOSTICS = gql`
  mutation UpsertDiagnostics(
    $id: ID
    $name: String!
    $active: Boolean!
    $heritage: Boolean!
    $annualInvestment: Boolean!
  ) {
    upsertDiagnostics(id: $id, name: $name, active: $active, heritage: $heritage, annualInvestment: $annualInvestment) {
      ${response}
      dataResult {
        id
        name
      }
    }
  }
`;

export const PERFOM_DIAGNOSTICS = gql`
  query PerfomDiagnostics($id: ID) {
    perfomDiagnostics(id: $id) {
      id
      name
      heritage
      annualInvestment
      themes {
        id
        name
        worth
        active
        questions {
          id
          question
          exposition
          worth
          active
        }
      }
    }
  }
`;

export const UPSERT_ANSWER_DIAGNOSTICS = gql`
  mutation UpsertAnswerDiagnostics(
    $answer: AnswerDiagnosticsInput
  ) {
    upsertAnswerDiagnostics(answer: $answer) {
      ${response}
    }
  }
`;

export const ANSWER_DIAGNOSTICSES = gql`
  query AnswerDiagnosticses($diagnosticses: [ID], $customers: [ID]) {
    answerDiagnosticses(diagnosticses: $diagnosticses, customers: $customers) {
      id
      diagnostics {
        id
        name
      }
      customer {
        id
        name
      }
      user {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export const ANSWER_DETAIL = gql`
  query AnswerDetail($idAnswerDiagnostics: ID) {
    answerDetail(idAnswerDiagnostics: $idAnswerDiagnostics) {
      id
      name
      heritage
      annualInvestment
      themes {
        id
        name
        questions {
          idAnswer
          id
          question
          exposition
          note
          qualification
        }
      }
      heritageValues {
        id
        heritageDate
        heritageValue
        annualInvestment
      }
    }
  }
`;

export const GRAPHIC_DIAGNOSTICSES = gql`
  query Graphics($idAnswerDiagnostics: ID!) {
    graphics(idAnswerDiagnostics: $idAnswerDiagnostics) {
      graphics {
        themeName
        themeWeight
        partialPercentaje
        totalPercentaje
        showRadar
        questions {
          note
          question
        }
      }
      heritage {
        heritageDate
        heritageValue
        lostValue
        probabilityPercentage
        annualInvestment
        savingsAchieved
      }
    }
  }
`;
