import gql from 'graphql-tag';
import { response } from '../utils/common';

export const TIME_REPORT = gql`
  query TimeReport(
    $projects: [ID]
    $participants: [ID]
    $costs: [String]
    $type: String
    $date: String
    $view: String
  ) {
    timeReport(
      projects: $projects
      participants: $participants
      costs: $costs
      type: $type
      date: $date
      view: $view
    ) {
      id
      hours
      minutes
      date
      start
      end
      billedTime
      notBilledTime
      description
      activity {
        id
        name
        project {
          id
          name
        }
      }
      user {
        id
        name
      }
      detail {
        id
        description
        hours
        minutes
        date
        billedTime
        notBilledTime
        activity {
          id
          name
          project {
            id
            name
          }
        }
        user {
          id
          name
        }
      }
    }
  }
`;

export const EXPORT_TIME_REPORT = gql`
  mutation ExportTimeReport($projects: [ID] $participants: [ID] $costs: [String] $type: String $start: String $end: String) {
   exportTimeReport(projects: $projects participants:$participants costs: $costs type: $type start: $start end: $end) {
      ${response}
    }
  }
`;

export const REPORT_TIME_FORM = gql`
  query ReportTimeForm {
    costList: projectsByResponsible {
      id
      costCenter
    }
    participantList: participantsByResponsible {
      id
      userInfo {
        id
        name
      }
    }
    projectList: projectsByResponsible {
      id
      name
    }
  }
`;

export const REPORT_TIME = gql`
  query ReportTime(
    $pagination: Pagination
    $filters: ReportTimeInput
    $sorter: Sorter
  ) {
    reportTime(filters: $filters, pagination: $pagination, sorter: $sorter) {
      total
      items {
        id
        description
        hours
        minutes
        date
        billedTime
        notBilledTime
        activity {
          id
          name
          project {
            id
            name
          }
        }
        user {
          id
          name
        }
      }
    }
  }
`;

export const EXPORT_REPORT_TIME = gql`
  mutation ExportReportTime($filters: ReportTimeInput $start: String $end: String) {
   exportReportTime(filters: $filters start: $start end: $end) {
      ${response}
    }
  }
`;

export const SEND_PDF_REPORT = gql`
mutation SendPdfEmail(
  $templateHtml: String, 
  $users: [String], 
  $projectName: String, 
  $projectId: String) {
  sendPdfEmail(
    templateHtml: $templateHtml, 
    users: $users, 
    projectName: $projectName, 
    projectId: $projectId) {
    ${response}
  }
}
`;
