import gql from 'graphql-tag';

export const QUALIFICATION_NAME = gql`
  query Qualification($id: ID) {
    qualification(id: $id) {
      id
      name
    }
  }
`;

export const QUALIFICATIONS = gql`
  query Qualifications {
    qualifications {
      id
      name
      percentage
      active
    }
  }
`;
