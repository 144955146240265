import gql from 'graphql-tag';
import { response } from '../utils/common';

export const STATES_FLOW = gql`
  query StatesFlow($flowId: ID!) {
    statesFlow(flowId: $flowId) {
      id
      name
    }
  }
`;

export const STATE = gql`
  query State($id: ID) {
    state(id: $id) {
      id
      name
    }
  }
`;

export const STATE_RESPONSIBLE = gql`
  query State($id: ID, $projectId: ID) {
    state(id: $id) {
      id
      name
      responsibleType {
        id
        name
      }
      responsible {
        id
        charge {
          id
          name
          participants(projectId: $projectId) {
            id
            user {
              id
              name
            }
          }
        }
      }
      visualizers {
        id
        user {
          id
          name
        }
      }
      activities {
        id
        name
      }
      documents {
        id
        name
      }
      start
      end
      next
      back
      status
    }
  }
`;

export const DELETE_STATE = gql`
  mutation DeleteState($id: ID!) {
    deleteState(id: $id) {
      ${response}
    }
  }
`;
