import gql from 'graphql-tag';
import { response } from '../utils/common';

const fields = `
  id
  diagnostics {
    id
    name
  }
  name
  description
  worth
  active
  graph
  createdAt
  updatedAt
`;

export const THEME_NAME = gql`
  query Theme($id: ID) {
    theme(id: $id) {
      id
      name
    }
  }
`;

export const THEMES = gql`
  query Themes {
    themes {
      ${fields}
    }
  }
`;

export const THEMES_BY_DIAGNOSTICS = gql`
  query ThemesByDiagnostics($diagnosticsId: ID) {
    themesByDiagnostics(diagnosticsId: $diagnosticsId) {
     ${fields}
    }
  }
`;

export const UPSERT_THEME = gql`
  mutation UpsertTheme(
    $id: ID!
    $diagnostics: ID!
    $name: String
    $description: String
    $worth: Float
    $active: Boolean
    $graph: Boolean
  ) {
    upsertTheme(
      id: $id
      diagnostics: $diagnostics
      name: $name
      description: $description
      worth: $worth
      active: $active
      graph: $graph) {
      ${response}
      dataResult {
        id
        name
      }
    }
  }
`;
