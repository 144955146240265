import React from 'react';
import * as PropTypes from 'prop-types';
import { Modal } from 'antd';
import IndividualForm from './IndividualForm';

const IndividualModal = ({ visible, ...others }) => (
  <Modal visible={visible} width="80%" {...others}>
    <IndividualForm {...others} />
  </Modal>
);

IndividualModal.propTypes = {
  visible: PropTypes.bool,
};

export default IndividualModal;
