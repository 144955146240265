import gql from 'graphql-tag';
import { response } from '../utils/common';

export const FIELDS_PROJECT = `
  id
  name
  description
  stateDate
  start
  end
  realEnd
  plannedAdvance
  realAdvance
  billable
  budget
  cost
  sell
  projectTemplate
  costCenter
  code
  practiceArea
  commercialManagerOne
  commercialManagerTwo
  participationPercentageOne
  participationPercentageTwo
  commissionPercentage
  state {
  	id
  	name
    next
    back
    cyclic
    transitionsStart {
      id
      start {
        id
        name
        cyclic
      }
      end {
        id
        name
        cyclic
      }
      sense {
        id
        name
      }
    }
  }
  origin {
  	id
  	name
  }
  type {
    id
    name
    structure {
    	id
    	name
    }    
  }
  participants {
    id
    user {
      id
      name
      photo {
        id
        url
      }
    }
  }
  flowVersion {
    id
    version
    flow {
      id
    }
  }
  customer{
    id
    name
    address
    city {
      id
      name
    }
  }
  serviceOrder {
    id
  }
  activities {
    id
    estimate
    stage {
      id
      end
    }
    responsible {
      id
      user {
        id
        name
      }
      user {
        id
        name
      }
    }
  }
`;

export const FIELDS_PROJECTS = `
  id
  name
  description
  stateDate
  start
  end
  realEnd
  plannedAdvance
  realAdvance
  billable
  budget
  cost
  sell
  projectTemplate
  costCenter
  code
  practiceArea
  commercialManagerOne
  commercialManagerTwo
  participationPercentageOne
  participationPercentageTwo
  commissionPercentage
  state {
  	id
  	name
    next
    back
    cyclic
  }
  type {
    id
    name   
  }
  participants {
    id
    user {
      id
      name
      #photo {
      #  id
      #  url
      #}
    }
  }
  customer{
    id
    name
    address
  }
  activities {
    id
    estimate
    stage {
      id
      end
    }
    responsible {
      id
      user {
        id
        name
      }
    }
  }
`;

export const UPSERT_PROJECT = gql`
  mutation UpsertProject(
    $id: ID
    $name: String
    $description: String
    $type: ID!
    $origin: ID
    $start: String
    $end: String
    $realEnd: String
    $plannedAdvance: Float
    $realAdvance: Float
    $billable: Boolean
    $budget: Float
    $cost: Float
    $sell: Float
    $phases: [ProjectInput]
    $participants: [ParticipantInput]
    $customer: ID
    $template: String
    $serviceOrder: ServiceOrderInput
    $costCenter: String
    $practiceArea: String
    $commercialManagerOne: String
    $commercialManagerTwo: String
    $participationPercentageOne: Int
    $participationPercentageTwo: Int
    $commissionPercentage: Int
  ) {
    upsertProject(
      id: $id
      name: $name
      description: $description
      type: $type
      origin: $origin
      start: $start
      end: $end
      realEnd: $realEnd
      plannedAdvance: $plannedAdvance
      realAdvance: $realAdvance
      billable: $billable
      budget: $budget
      cost: $cost
      sell: $sell
      phases: $phases
      participants: $participants
      customer: $customer
      template: $template
      serviceOrder: $serviceOrder
      costCenter: $costCenter
      practiceArea: $practiceArea
      commercialManagerOne: $commercialManagerOne
      commercialManagerTwo: $commercialManagerTwo
      participationPercentageOne: $participationPercentageOne
      participationPercentageTwo: $participationPercentageTwo
      commissionPercentage: $commissionPercentage
    ) {
      ${response}
      dataResult {
        ${FIELDS_PROJECT}
      }
    }
  }
`;

export const UPDATE_PROJECT_STATE = gql`
  mutation UpdateProjectState(
    $state: ID
    $responsible: [ID]
    $visualizers: [ID]
    $project: ID
    $cyclic: Boolean
  ) {
    updateProjectState(
      state: $state
      responsible: $responsible
      visualizers: $visualizers
      project: $project
      cyclic: $cyclic
    ) {
      ${response}
      dataResult{
        id
        name
        state {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: ID) {
    deleteProject(id: $id) {
      ${response}
    }
  }
`;

export const PROJECT_BY_TEMPLATE = gql`
  mutation UpsertProjectByTemplate($templateId: ID) {
    upsertProjectByTemplate(templateId: $templateId) {
      ${response}
      dataResult{
        id
      }
    }
  }
`;

export const PROJECTS = gql`
  query Projects($after: String, $first: Int, $orderBy: String, $filters: String) {
    projects(after: $after, first: $first, orderBy: $orderBy, filters: $filters) {
      ${FIELDS_PROJECTS}
    }
  }
`;

export const PROJECTS_LIST = gql`
  query ProjectsList {
    projectsList {
      id
      name
    }
  }
`;

export const PROJECTS_LIST_WITH_TYPES = gql`
  query ProjectsList {
    projectsList {
      id
      name
      type {
        name
        id
      }
      projectTemplate
      description
    }
  }
`;

export const PROJECTS_STATE = gql`
  query ProjectsStates {
    projectsList {
      id
      name
      state {
        id
        name
      }
    }
  }
`;

export const PROJECT = gql`
  query Project($id: ID $type: ID) {
    project(id: $id type: $type) {
      ${FIELDS_PROJECT}
    }
  }
`;

export const VALIDATION_TRANSITIONS = gql`
  query ValidateTransitions($projectId: ID, $state: ID) {
    validateTransitions(projectId: $projectId, state: $state) {
      message
    }
  }
`;

export const PROJECTS_BY_RESPONSIBLE = gql`
  query ProjectsByResponsible {
    projectsByResponsible {
      id
      name
    }
  }
`;

export const PROJECTS_BY_RESPONSIBLE_SUMMARY = gql`
  query projectsByResponsibleSummary($after: String, $first: Int, $orderBy: String, $filters: String) {
    projectsByResponsibleSummary(after: $after, first: $first, orderBy: $orderBy, filters: $filters) {
      ${FIELDS_PROJECTS}
    }
  }
`;

export const COSTS_BY_RESPONSIBLE = gql`
  query ProjectsByResponsible {
    projectsByResponsible {
      costCenter
    }
  }
`;

export const SUMMARY_PROJECT = gql`
  query SummaryProject {
    summaryProject {
      countProjects
      countResponsible
      countCommitment
    }
  }
`;

export const REPORT_PROJECTS_FORM = gql`
  query ReportProjectsForm {
    customerList: customersList {
      id
      name
    }
    projectList: projectsList {
      id
      name
    }
    statusList: stateList {
      id
      name
    }
    typeList: activeProjectTypes {
      id
      name
    }
  }
`;

export const SEARCH_PROJECTS = gql`
  query SearchProjects($term: String) {
    projectList: searchProject(term: $term) {
      id
      name
    }
  }
`;

export const REPORT_PROJECTS = gql`
  query ReportProjects(
    $pagination: Pagination
    $filters: ReportProjectsInput
    $sorter: Sorter
  ) {
    reportProjects(
      filters: $filters
      pagination: $pagination
      sorter: $sorter
    ) {
      total
      items {
        id
        code
        name
        createdAt
        customer {
          id
          name
        }
        state {
          id
          name
        }
        type {
          id
          name
        }
      }
    }
  }
`;

export const EXPORT_REPORT_PROJECTS = gql`
    mutation ExportReportProjects($filters: ReportProjectsInput $start: String $end: String) {
        exportReportProjects(filters: $filters start: $start end: $end) {
            ${response}
        }
    }
`;
