import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row, Table as AntTable, ConfigProvider } from 'antd';

const Table = ({
  labels,
  data,
  titles,
  renders,
  actions,
  pagination,
  rowKey,
  footer,
  rowSelection,
  handleTable = () => {},
  ...rest
}) => {
  const { t } = useTranslation();

  let columns = Object.keys(titles).map(x => ({
    dataIndex: x,
    key: x,
    title: t(titles[x]),
  }));

  if (renders && renders.length > 0) {
    for (const render of renders) {
      const record = columns.find(({ key }) => render.key === key);
      const index = columns.indexOf(record);

      columns[index] = {
        ...record,
        ...render,
      };
    }
  }

  if (actions) {
    const renderActions = (text, record) => (
      <div className="custom-table-buttons">
        <Row gutter={8} type="flex">
          {actions.map((x, index) => (
            <Col key={index}>
              {x({
                record,
                text,
              })}
            </Col>
          ))}
        </Row>
      </div>
    );

    const actionProps = columns.find(({ key }) => key === 'actions');

    columns = columns.concat([
      {
        dataIndex: '',
        key: 'actions',
        render: renderActions,
        title: t('common.actions'),
        width: 140,
        ...actionProps,
      },
    ]);
  }

  return (
    <AntTable
      rowSelection={rowSelection}
      className="custom-table"
      dataSource={data}
      columns={columns}
      rowKey={rowKey}
      footer={footer}
      pagination={{ ...pagination, showSizeChanger: true }}
      onChange={handleTable}
    />
  );
};

Table.propTypes = {
  t: PropTypes.func,
  actions: PropTypes.array,
  data: PropTypes.array,
  footer: PropTypes.func,
  handleTable: PropTypes.func,
  labels: PropTypes.object,
  pagination: PropTypes.object,
  renders: PropTypes.array,
  rowKey: PropTypes.string.isRequired,
  rowSelection: PropTypes.object,
  titles: PropTypes.object.isRequired,
};

export default Table;
