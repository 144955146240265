import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Modal, Button } from 'antd';
import TeamDetail from './TeamDetail';
import IndividualForm from './IndividualForm';

const { confirm } = Modal;

const TimeForm = ({
  visible,
  form,
  record,
  onOk,
  hideOk,
  onCancel,
  onDelete,
  ...rest
}) => {
  const { t } = useTranslation();

  const showDeleteConfirm = () => {
    confirm({
      title: t('timeRecord.confirmDelete'),
      content: t('timeRecord.descriptionDelete'),
      okText: t('common.yes'),
      okType: 'danger',
      cancelText: t('common.no'),
      onOk() {
        onDelete(record.id);
      },
      okButtonProps: {
        className: 'custom-modal-delete',
      },
    });
  };

  return (
    <Modal
      visible={visible}
      title={
        hideOk
          ? t('timeRecord.timeRecord')
          : record.id
          ? record.detail
            ? `${t('timeRecord.detail')} - ${record.user.name}`
            : t('timeRecord.update')
          : t('timeRecord.create')
      }
      okText={t('timeRecord.save')}
      cancelText={t('timeRecord.cancel')}
      afterClose={form.resetFields}
      maskClosable={false}
      onOk={onOk}
      onCancel={onCancel}
      width="80%"
      {...rest}
      footer={
        !(record.detail || hideOk) && (
          <div>
            <Button key={1} type="default" onClick={onCancel}>
              {t('timeRecord.cancel')}
            </Button>
            <Button key={2} type="primary" onClick={() => onOk(form)}>
              {t('timeRecord.save')}
            </Button>
            {record && record.id && (
              <Button key={3} type="danger" onClick={showDeleteConfirm}>
                {t('delete')}
              </Button>
            )}
          </div>
        )
      }
    >
      {record.detail ? (
        <TeamDetail
          {...rest}
          hideOk={hideOk}
          details={record.detail}
          FormTag={Form}
          form={form}
        />
      ) : (
        <IndividualForm
          {...rest}
          hideOk={hideOk}
          FormTag={Form}
          record={record}
          form={form}
        />
      )}
    </Modal>
  );
};

TimeForm.propTypes = {
  visible: PropTypes.bool,
  hideOk: PropTypes.bool,
  record: PropTypes.object,
  form: PropTypes.object,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  activities: PropTypes.array,
  onDelete: PropTypes.func,
};

export default Form.create({})(TimeForm);
