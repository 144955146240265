import React from 'react';
import { Modal } from 'antd';
import PropsType from 'prop-types';

const ModalGeneric = ({ children, width, ...props }) => (
  <Modal centered {...props} width={width || '95%'}>
    {children}
  </Modal>
);

ModalGeneric.propTypes = {
  title: PropsType.string,
  visible: PropsType.bool,
  onOk: PropsType.func,
  onCancel: PropsType.func,
  okButtonProps: PropsType.object,
  cancelButtonProps: PropsType.object,
  children: PropsType.any,
  style: PropsType.object,
  okText: PropsType.string,
  cancelText: PropsType.string,
  confirmLoading: PropsType.bool,
  footer: PropsType.object,
  width: PropsType.string,
};

export default ModalGeneric;
