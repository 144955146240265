import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useTranslation } from 'react-i18next';
import {
  TIME_RECORDS,
  ACTIVITIES,
  UPSERT_TIME_RECORD,
  DELETE_TIME_RECORD,
} from '../../../graphql';
import { ApolloTasks } from '../../Shared';
import ButtonsProject from '../ButtonsProject';
import TimeForm from './TimeForm';
import { show } from '../../../utils/message';
import CalendarView from './CalendarView';

const TimeRecord = ({
  projectId,
  // eslint-disable-next-line no-unused-vars
  interactions,
  buttons,
  history,
  isTemplate,
  ...rest
}) => {
  const [timeRecords, setTimeRecords] = useState(undefined);
  const [activities, setActivities] = useState(undefined);
  const [upsert, setUpsert] = useState(undefined);
  const [deleteTime, setDeleteTime] = useState(undefined);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState({});
  const [type, setType] = useState('individual');
  const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (interactions && interactions.length > 0) {
      setDisabled(!interactions.filter(x => x.name === 'edit').length > 0);
    }
  }, [interactions]);

  const options = [
    {
      key: 'individual',
      icon: 'user',
      name: t('timeRecord.individual'),
    },
    {
      key: 'team',
      icon: 'team',
      name: t('timeRecord.team'),
    },
  ];

  const handleFilter = e => {
    const { key } = e;
    setType(key);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const updateCache = (cache, { data }) => {
    const { dataResult } = data.upsertTimeRecord;

    cache.writeQuery({
      query: TIME_RECORDS,
      variables: { projectId, type },
      data: { timeRecords: dataResult },
    });
  };

  const onDelete = async idTime => {
    const { deleteTimeRecord } = (await deleteTime({
      variables: {
        id: idTime,
      },
    })).data;
    if (deleteTimeRecord.result) {
      show({
        message: deleteTimeRecord.message,
        type: deleteTimeRecord.result ? 'success' : 'warning',
      });
      setVisible(false);
    }
  };

  const onOk = form => {
    form.validateFields(async (err, values) => {
      if (!err) {
        const { time, date } = values;
        if (time.hours() === 0 && time.minutes() === 0) {
          show({ message: t('timeRecord.messageTime'), type: 'warning' });
          return;
        }
        const { upsertTimeRecord } = (await upsert({
          variables: {
            ...values,
            id: (selected && selected.id) || undefined,
            date: new Date(date.format('YYYY/MM/DD')),
            hours: time.hours(),
            minutes: time.minutes(),
            project: projectId,
            type,
            billedTime:
              (values.billedTime &&
                moment(values.billedTime).format('HH:mm')) ||
              null,
            notBilledTime:
              (values.notBilledTime &&
                moment(values.notBilledTime).format('HH:mm')) ||
              null,
          },
        })).data;

        if (upsertTimeRecord.result) {
          show({
            message: upsertTimeRecord.message,
            type: upsertTimeRecord.result ? 'success' : 'warning',
          });

          setVisible(false);
        }
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  const onSelect = (record, event) => {
    setSelected(record);
    setVisible(true);
  };

  const onSelectCreate = event => {
    setSelected({ date: moment(event.start) });
    setVisible(true);
  };

  const tasks = [
    {
      setFunction: setTimeRecords,
      state: timeRecords,
      query: {
        query: TIME_RECORDS,
        accessData: 'timeRecords',
        props: {
          fetchPolicy: 'network-only',
          variables: { projectId, type },
        },
      },
    },
    {
      setFunction: setActivities,
      state: activities,
      query: {
        query: ACTIVITIES,
        accessData: 'activities',
        props: { fetchPolicy: 'network-only', variables: { projectId } },
      },
    },
    {
      setFunction: setUpsert,
      state: upsert,
      mutation: {
        mutation: UPSERT_TIME_RECORD,
        props: {
          update: updateCache,
          refetchQueries: [
            { query: ACTIVITIES, variables: {}, fetchPolicy: 'network-only' },
          ],
        },
      },
    },
    {
      setFunction: setDeleteTime,
      state: deleteTime,
      mutation: {
        mutation: DELETE_TIME_RECORD,
        props: {
          update: updateCache,
          refetchQueries: [
            {
              query: TIME_RECORDS,
              variables: { projectId, type },
              fetchPolicy: 'network-only',
            },
          ],
        },
      },
    },
  ];

  return (
    <div className="custom-project-padding">
      <Row>
        <Col>
          <ApolloTasks tasks={tasks}>
            <div>
              <div className="custom-time-record-calendar-container">
                <CalendarView
                  timeRecords={timeRecords}
                  onSelect={onSelect}
                  onSelectCreate={onSelectCreate}
                  handleFilter={handleFilter}
                  type={type}
                  options={options}
                />
                <ButtonsProject
                  {...rest}
                  id={projectId}
                  buttons={buttons}
                  history={history}
                  isTemplate={isTemplate}
                />
              </div>
              <TimeForm
                visible={visible}
                record={selected}
                activities={activities}
                onCancel={onCancel}
                onOk={onOk}
                hideOk={disabled}
                onDelete={onDelete}
              />
            </div>
          </ApolloTasks>
        </Col>
      </Row>
    </div>
  );
};

TimeRecord.propTypes = {
  projectId: PropTypes.string,
  interactions: PropTypes.array,
  buttons: PropTypes.array,
  history: PropTypes.object,
  isTemplate: PropTypes.bool,
};

export default withRouter(TimeRecord);
