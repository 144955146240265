import gql from 'graphql-tag';
import { response } from '../utils/common';

export const DOCUMENT_FIELDS = `
  id
  type
  name
  type
  path
  nameFile
  documentStatus {
    id
    name
    color
  }
  project {
    id
  }
  recordType {
    id
    name
  } 
  author {
    id
    name
  }
`;

export const DOCUMENT_STATES = gql`
  query DocumentStates {
    documentStates {
      id
      name
      color
    }
  }
`;

export const DOCUMENT = gql`
  query Document($id: ID) {
    document(id: $id) {
     ${DOCUMENT_FIELDS}
    }
  }
`;

export const DOCUMENTS = gql`
  query Documents($recordType: ID, $project: ID, $stateId: ID) {
    documents(recordType: $recordType, project: $project, stateId: $stateId) {
     ${DOCUMENT_FIELDS}
    }
  }
`;

export const UPSERT_DOCUMENT = gql`
  mutation UpsertDocument(
    $id: ID
    $type: String
    $name: String!
    $author: ID
    $path: String
    $documentStatus: ID!
    $project: ID!
    $recordType: ID
    $file: FileInput
  ) {
    upsertDocument(
      id: $id
      type: $type
      name: $name
      author: $author
      path: $path
      documentStatus: $documentStatus
      project: $project
      recordType: $recordType
      file: $file
    ) {
      ${response}
    }
  }
`;

export const DOCUMENTS_TEMPLATE = gql`
  query DocumentsTemplate($flowId: ID, $stateId: ID) {
    documentsTemplate(flowId: $flowId, stateId: $stateId) {
      id
      name
    }
  }
`;
