import gql from 'graphql-tag';

export const PROJECT_TYPE = gql`
  query ProjectType($id: ID!) {
    projectType(id: $id) {
      id
      name
      projects {
        id
      }
    }
  }
`;

export const PROJECT_TYPE_NAME = gql`
  query ProjectType($id: ID) {
    projectType(id: $id) {
      id
      name
    }
  }
`;

export const PROJECT_TYPE_LIST = gql`
  query ProjectTypes {
    projectTypes {
      id
      name
    }
  }
`;

export const PROJECTS_TYPE = gql`
  query ProjectTypes {
    projectTypes {
      id
      name
      flows {
        id
        name
        versions {
          id
          version
          states {
            id
            start
            name
          }
        }
      }
      projects {
        isTemplate
        id
        name
        templateName
      }
    }
  }
`;

export const PROJECT_TYPE_FLOW = gql`
  query ProjectTypeFlow($flowId: ID) {
    projectTypeFlow(flowId: $flowId) {
      id
      name
    }
  }
`;
