import { URL_WITHOUT_LAYOUT } from '../config/constants';

export const nextNumber = (next = 1) => {
  let n = next;
  return () => {
    n += 1;
    return n;
  };
};

const nextRouteIndex = nextNumber();
export const createRoute = (
  url,
  component,
  key,
  when = null,
  exact = false,
  withLayout = true
) => ({
  index: nextRouteIndex(),
  path: withLayout ? url : `${URL_WITHOUT_LAYOUT}${url}`,
  component,
  key,
  when,
  exact: exact || false,
  withLayout,
});

const nextMenuIndex = nextNumber();
export const createMenu = (url, title, icon, when, key) => ({
  index: nextMenuIndex(),
  title,
  path: url,
  icon,
  when,
  key,
});

export const createMenuParent = (title, icon, when, key, itemsSubmenu) => ({
  index: nextMenuIndex(),
  title,
  icon,
  when,
  key,
  itemsSubmenu,
});

export const createComponent = (component, when, key) => ({
  index: nextMenuIndex(),
  component,
  when,
  key,
});

export const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const sortString = (key, keyObject = 'name') => (a, b) => {
  if (
    (a[key] && typeof a[key] === 'object') ||
    (b[key] && typeof b[key] === 'object')
  ) {
    const valueA = a[key] && a[key][keyObject];
    const valueB = b[key] && b[key][keyObject];
    return (valueA || '').localeCompare(valueB || '');
  }
  return (a[key] || '').localeCompare(b[key] || '');
};
export const sortNumber = key => (a, b) => a[key] - b[key];
export const sortBool = key => (a, b) => b[key] - a[key];

export const capitalize = s => {
  if (typeof s !== 'string' || s.length === 0) {
    return '';
  }

  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
};

export const formItemLayoutGeneral = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
};

export const formItemMultiLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

export const moneyFormatter = value =>
  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const moneyParser = value => value.replace(/\$\s?|(,*)/g, '');

export const percentFormatter = value => `${value}%`;

export const percentParser = value => value.replace('%', '');

export const upperInitials = value =>
  ((value && value.length > 0 && value.split(' ')) || []).reduce(
    (result, next) => result + next.substring(0, 1).toUpperCase(),
    ''
  );

export const fileDummy = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

export const normFile = e => {
  if (Array.isArray(e)) {
    return e;
  }

  if (e.fileList.length > 1) {
    e.fileList.shift();
  }

  return e && e.fileList;
};

export const addTimes = (startTime, endTime) => {
  const times = [0, 0, 0];
  const max = times.length;

  const a = (startTime || '').split(':');
  const b = (endTime || '').split(':');

  // normalize time values
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < max; i++) {
    a[i] = !parseInt(a[i]) ? 0 : parseInt(a[i]);
    b[i] = !parseInt(b[i]) ? 0 : parseInt(b[i]);
  }

  // store time values
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < max; i++) {
    times[i] = a[i] + b[i];
  }

  let hours = times[0];
  let minutes = times[1];
  let seconds = times[2];

  if (seconds >= 60) {
    const m = (seconds / 60) << 0;
    minutes += m;
    seconds -= 60 * m;
  }

  if (minutes >= 60) {
    const h = (minutes / 60) << 0;
    hours += h;
    minutes -= 60 * h;
  }

  return `${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(
    -2
  )}:${`0${seconds}`.slice(-2)}`;
};

export const filterOptionAccents = (input, option) =>
  option.title
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .indexOf(
      input
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    ) >= 0;
