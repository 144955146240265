import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Icon, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { colorByHash } from '../../../utils';
import { addTimes } from '../../../utils/general';

const RecordInformation = ({
  activity,
  user,
  hours,
  minutes,
  detail,
  type,
  ...rest
}) => {
  const { t } = useTranslation();
  let time = `${hours > 9 ? hours : `0${hours}`}:${
    minutes > 9 ? minutes : `0${minutes}`
  }`;
  let endHours;

  if ((type === 'team' || type === 'individualReport') && detail) {
    let sumTime = '00:00';
    detail.forEach(value => {
      sumTime = addTimes(sumTime, `${value.hours}:${value.minutes}`);
    });
    const [_housr, _minutes] = sumTime.split(':');
    endHours = _housr;
    const endMinutes = _minutes;
    // endHours = detail.reduce((total, value) => total + value.hours, 0);
    // const endMinutes = detail.reduce(
    //   (total, value) => total + value.minutes,
    //   0
    // );
    time = `${endHours}:${endMinutes}`;
  }

  return (
    <Tooltip placement="top" title={`${t('timeRecord.time')}: ${time}`}>
      <div
        className="custom-record-information-container"
        style={{
          backgroundColor: colorByHash(`${user.id}:${user.name}`),
        }}
      >
        {(type === 'team' || type === 'individualReport') && (
          <div>
            <Row type="flex">
              <Col span={24}>
                <h4>{user.name}</h4>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="end"
              className="custom-time-record-icon-row"
            >
              <Col span={20}>
                <span>{time}</span>
              </Col>
              <Col span={4}>
                <Icon
                  className="custom-time-record-icon"
                  type={endHours >= 7 ? 'smile' : 'frown'}
                  theme="filled"
                  style={{
                    fontSize: '24px',
                    color: `${endHours >= 7 ? '#fff479' : '#ff9800'}`,
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
        {type === 'individual' && activity && activity.name && (
          <span>
            {activity.name} - {time}
          </span>
        )}
      </div>
    </Tooltip>
  );
};

RecordInformation.propTypes = {
  activity: PropTypes.object,
  user: PropTypes.object,
  detail: PropTypes.array,
  hours: PropTypes.number,
  minutes: PropTypes.number,
  type: PropTypes.string,
};

export default RecordInformation;
