import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spin } from 'antd';

const WrapperCenter = ({
  loading = false,
  alignContent = 'left',
  children,
  style = {},
  padding = 20,
  properties = {},
}) => (
  <Row type="flex" {...properties} style={style}>
    <Col style={{ width: '100%', padding, textAlign: alignContent }}>
      <Spin spinning={loading}>{children}</Spin>
    </Col>
  </Row>
);

WrapperCenter.propTypes = {
  children: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  alignContent: PropTypes.string,
  style: PropTypes.object,
  properties: PropTypes.object,
  padding: PropTypes.any,
};

export default WrapperCenter;
