import { Button, Form, Icon, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { show } from '../../utils/message';

function Login({ loading, form, authentication, domain }) {
  const { getFieldDecorator } = form;
  const { t } = useTranslation();

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const { login } = (await authentication(
          values.email,
          values.password
        )).data;
        show({
          message: login.response.message,
          type: login.response.result ? 'success' : 'warning',
        });
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit} className="custom-form-login">
      <h1 className="custom-login-title">{t('login.title')}</h1>
      <Form.Item>
        {getFieldDecorator('email', {
          rules: [
            { required: true, message: t('loginValidation.emailRequired') },
            { type: 'email', message: t('loginValidation.emailInvalid') },
          ],
        })(
          <Input
            className="custom-text-input"
            prefix={<Icon type="user" className="custom-prefix-icon" />}
            placeholder={t('login.email')}
            size="large"
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('password', {
          rules: [
            { required: true, message: t('loginValidation.passwordRequired') },
          ],
        })(
          <Input
            className="custom-text-input"
            prefix={<Icon type="lock" className="custom-prefix-icon" />}
            type="password"
            size="large"
            placeholder={t('login.password')}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={loading}
          className="custom-button"
          shape="round"
        >
          {t('login.login')}
        </Button>
      </Form.Item>
      <Link
        to={`/raw/forgotPassword/${domain || ''}`}
        className="custom-forgot-link"
      >
        {t('login.forgotPassword')}
      </Link>
    </Form>
  );
}

const formShape = {
  validateFields: PropTypes.func,
  getFieldDecorator: PropTypes.func,
};

Login.propTypes = {
  domain: PropTypes.string,
  form: PropTypes.shape(formShape).isRequired,
  loading: PropTypes.bool,
  authentication: PropTypes.func,
};

export default Form.create({ name: 'normal_login' })(Login);
