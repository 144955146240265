import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Icon, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Title } from '../Shared';

const FormItem = Form.Item;

const ResetPassword = ({ form, submit, loading, user }) => {
  const { getFieldDecorator } = form;
  const { t } = useTranslation();

  function onSubmit(e) {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        values.email = user.email;
        submit(values);
      }
    });
  }

  return (
    <Form onSubmit={onSubmit} className="custom-form-forgot" autoComplete="off">
      <Title text={t('resetPassword.title')} />
      <span>{t('resetPassword.description')}</span>
      <FormItem
        style={{ textAlign: 'left' }}
        label={t('resetPassword.password')}
      >
        {getFieldDecorator('password', {
          rules: [
            { required: true, message: t('resetPassword.passwordRequired') },
          ],
        })(
          <Input.Password
            prefix={<Icon type="lock" className="custom-prefix-icon" />}
            placeholder={t('resetPassword.password')}
            size="large"
          />
        )}
      </FormItem>
      <FormItem
        style={{ textAlign: 'left' }}
        label={t('resetPassword.confirmPassword')}
      >
        {getFieldDecorator('confirmPassword', {
          rules: [
            { required: true, message: t('resetPassword.passwordRequired') },
          ],
        })(
          <Input.Password
            prefix={<Icon type="lock" className="custom-prefix-icon" />}
            placeholder={t('resetPassword.confirmPassword')}
            size="large"
          />
        )}
      </FormItem>
      <FormItem>
        <Button
          className="custom-button"
          type="primary"
          size="large"
          htmlType="submit"
          loading={loading}
          shape="round"
        >
          {t('resetPassword.changePassword')}
        </Button>
      </FormItem>
    </Form>
  );
};

ResetPassword.propTypes = {
  form: PropTypes.object,
  user: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  submit: PropTypes.func,
};

export default Form.create()(ResetPassword);
