import gql from 'graphql-tag';

export const MENUS = gql`
  query Menus {
    menus {
      id
      name
    }
  }
`;
