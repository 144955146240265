import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import { useTimeDetail } from '../../../hooks/Time';
import { Table, ApolloTasks } from '../../Shared';
import IndividualModal from './IndividualModal';

const TeamDetail = ({ details, form, ...props }) => {
  const {
    columns,
    renders,
    detail,
    handleEdit,
    tasks,
    ...rest
  } = useTimeDetail({
    form,
  });

  const { t } = useTranslation();

  const actions = [
    ({ record }) => (
      <Tooltip title={t('time.record.edit')} placement="top">
        <Button
          type="primary"
          shape="circle"
          icon="edit"
          onClick={() => handleEdit(record)}
        />
      </Tooltip>
    ),
  ];

  return (
    <ApolloTasks tasks={tasks}>
      <Table
        rowKey="id"
        data={details}
        titles={columns}
        renders={renders}
        actions={actions}
      />
      <IndividualModal {...props} {...rest} {...detail} form={form} />
    </ApolloTasks>
  );
};

TeamDetail.propTypes = {
  details: PropTypes.array,
  form: PropTypes.object,
};

export default TeamDetail;
