import gql from 'graphql-tag';
import { response } from '../utils/common';

const flowData = `states {
        id
        temporalId
        name
        next
        back
        start
        end
        type
        size
        shape
        color
        x
        y
        index
        status        
        responsibleType {
          id
          name
        }
        responsible {
          id
          charge {
            id
            name
          }
        }
        visualizers {
          id
          user {
            id
            name
          }
        }
        activities {
          id
          name
        }
        documents {
          id
          name
        }
        serviceOrder
        cyclic
      }
      transitions {
        id
        temporalId
        name
        shape
        startAnchor
        endAnchor
        index
        status
        notification {
          id
          name
        }
        sense {
          id
          name
        }
        start {
          id
        }
        end {
          id
        }
        activities {
          id
          name
        }
        documents {
          id
          name
        }          
      }`;

export const FLOW_NAME = gql`
  query Flow($id: ID) {
    flow(id: $id) {
      id
      name
    }
  }
`;

export const ACTUAL_FLOW = gql`
  query ActualFlow($flowId: ID!) {
    actualFlow(flowId: $flowId) {
      ${flowData}
    }
  }
`;

export const SAVE_FLOW = gql`
  mutation SaveFlow($flow: FlowVersionInput) {
    saveFlow(flow: $flow) {
      ${response}
      dataResult {
        ${flowData}
      }
    }
  }
`;
