import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import RecordInformation from './RecordInformation';
import Toolbar from './Toolbar';

const localizer = momentLocalizer(moment);

const allViews = {
  month: true,
  week: true,
  work_week: false,
  day: true,
  agenda: false,
};

const CalendarView = ({
  timeRecords,
  onSelect,
  onSelectCreate,
  handleFilter,
  type,
  options,
  refreshQuery,
  ...rest
}) => {
  const { i18n } = useTranslation();
  return (
    <Calendar
      className="custom-time-record-calendar"
      events={timeRecords || []}
      defaultView={window.innerWidth < 767 ? Views.DAY : Views.WEEK}
      views={allViews}
      onSelectEvent={onSelect}
      showMultiDayTimes
      culture={i18n.language}
      selectable
      onSelectSlot={onSelectCreate}
      onSelecting={() => false}
      step={type === 'team' ? 5 : 15}
      defaultDate={new Date()}
      localizer={localizer}
      titleAccessor={record => <RecordInformation {...record} type={type} />}
      components={{
        toolbar: props =>
          Toolbar({
            handleFilter,
            type,
            options,
            ...props,
          }),
      }}
      tooltipAccessor={null}
      startAccessor={record => new Date(record.start)}
      endAccessor={record => new Date(record.end)}
      onNavigate={(date, view) => {
        if (refreshQuery) refreshQuery(date, view);
      }}
      onView={view => {
        if (refreshQuery) refreshQuery(undefined, view);
      }}
    />
  );
};

CalendarView.propTypes = {
  type: PropTypes.string,
  options: PropTypes.array,
  timeRecords: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectCreate: PropTypes.func,
  handleFilter: PropTypes.func,
  refreshQuery: PropTypes.func,
};

export default CalendarView;
