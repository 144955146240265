import gql from 'graphql-tag';
import { ACTIVITY_FIELDS } from './activity';

export const STAGES_PROJECT = gql`
  query StagesProject($projectId: ID, $stateId: ID, $isTemplate: Boolean, $templateFilter: Boolean) {
    stagesProject(projectId: $projectId, isTemplate: $isTemplate) {
      id
      name
      start
      end
      activities(projectId: $projectId, stateId: $stateId, templateFilter: $templateFilter) {
        ${ACTIVITY_FIELDS}
      }
    }
  }
`;

export const STAGE_NAME = gql`
  query Stage($id: ID) {
    stage(id: $id) {
      id
      name
    }
  }
`;
