import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ type, logo, logoExpanded }) => (
  <div className="custom-logo-container">
    <img className="custom-logo" src={logo} alt="cra-rr4-redux-antd" />
    <img
      className="custom-logo-collapse"
      style={
        type === 'large'
          ? { width: '300px' }
          : { width: 0, opacity: 0, visibility: 'hidden' }
      }
      src={logoExpanded}
      alt="cra-rr4-redux-antd"
    />
  </div>
);

Logo.propTypes = {
  type: PropTypes.string,
  logo: PropTypes.string,
  logoExpanded: PropTypes.string,
};

export default Logo;
