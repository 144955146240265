export const GUEST = 'guest';
export const LOGGED = 'logged';
export const EDIT = 'edit';
export const URL_WITHOUT_LAYOUT = '/raw';

export const URL_LOCALIZATION = '/locales/{{lng}}/{{ns}}.json';
export const VERSION_LOCALIZATION = 5;
export const TIME_CACHE_LOCALIZATION = 7 * 24 * 60 * 60 * 1000;
export const LANGUAGES = {
  es: 'es',
  en: 'en',
  default: 'es',
};
// current version deploy stage: 1.5, prod: 1.5
export const VERSION_LANGUAJE = {
  es: '1.6',
  en: '1.3',
};
export const TEMPLATE = 'YXBsaWNh';
export const PRIMARY_COLOR = '#6800C0';

export const DEFAULT_PAGINATION = { skip: 0, first: 10, unsetted: true };

export const TYPE_SLOPE = [
  {
    value: 'Pendiente',
  },
  {
    value: 'Finalizado',
  },
];

export const LIMIT_UPLOAD_FILE = 4;

export const COMPANY = 'cognox';

export const PERFOM_DIAGNOSIS_NEW = 'VC45a';
