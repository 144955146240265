import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Tooltip, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import TimeFilter from './TimeFilter';

const buttonType = (view, equals) => (view === equals ? 'primary' : 'button');

const Toolbar = ({
  onView,
  view,
  onNavigate,
  label,
  handleFilter,
  type,
  options,
}) => {
  const { t } = useTranslation();

  return (
    <div className="custom-toolbar-container">
      <Row type="flex">
        <Col xs={24} md={6}>
          <Button.Group>
            <Button
              className="custom-empty-border-calendar-button"
              type="button"
              onClick={() => onNavigate('PREV')}
              key="left"
              icon="left"
            />
            <Button
              className="custom-empty-border-calendar-button"
              type="button"
              onClick={() => onNavigate('NEXT')}
              key="right"
              icon="right"
            />
          </Button.Group>
        </Col>
        <Col xs={24} md={6} className="custom-calendar-date-label-container">
          <span className="custom-calendar-date-label">{label}</span>
        </Col>
        <Col xs={24} md={10}>
          <Row>
            <Button.Group>
              <Button
                type={buttonType(view, 'day')}
                onClick={() => onView('day')}
              >
                {t('timeRecord.day')}
              </Button>
              <Button
                type={buttonType(view, 'week')}
                onClick={() => onView('week')}
              >
                {t('timeRecord.week')}
              </Button>
              <Button
                type={buttonType(view, 'month')}
                onClick={() => onView('month')}
              >
                {t('timeRecord.month')}
              </Button>
            </Button.Group>
            <Dropdown
              overlay={
                <TimeFilter
                  handleFilter={handleFilter}
                  type={type}
                  options={options}
                />
              }
            >
              <Tooltip title={t('timeRecord.filter')}>
                <Button className="custom-filter-button" icon="filter" />
              </Tooltip>
            </Dropdown>
          </Row>
        </Col>
      </Row>

      {/* <Col xs={12} md={6}>
        <Dropdown
          overlay={
            <TimeFilter
              handleFilter={handleFilter}
              type={type}
              options={options}
            />
          }
        >
          <Tooltip title={t('timeRecord.filter')}>
            <Button className="custom-filter-button" icon="filter" />
          </Tooltip>
        </Dropdown>
      </Col> */}
    </div>
  );
};

Toolbar.propTypes = {
  options: PropTypes.array,
  onView: PropTypes.func,
  onNavigate: PropTypes.func,
  handleFilter: PropTypes.func,
  type: PropTypes.string,
  view: PropTypes.string,
  label: PropTypes.string,
};

export default Toolbar;
