import { useState, useEffect } from 'react';
import { useAuthenticated } from '../Auth';
import { GUEST, LOGGED } from '../../config/constants';
import myMenus from '../../config/menus';

export function useMenu(position, menus = []) {
  const { isAuthenticated } = useAuthenticated();
  const [finalMenu, setFinalMenu] = useState(myMenus[position]);

  useEffect(() => {
    const filterSecurity = menu =>
      menu.when === undefined ||
      (isAuthenticated === false && menu.when === GUEST) ||
      (isAuthenticated === true &&
        menu.when === LOGGED &&
        ((menus || []).map(x => x.name).includes(menu.key) ||
          menu.key === '*' ||
          menu.key === 'profile' ||
          menu.key === 'dashboard')) ||
      [];

    setFinalMenu(
      myMenus[position].filter(filterSecurity).map(menu => {
        if (menu && menu.itemsSubmenu) {
          return {
            ...menu,
            itemsSubmenu: menu.itemsSubmenu.filter(
              x =>
                x.when === undefined ||
                (isAuthenticated === false && x.when === GUEST) ||
                (isAuthenticated === true &&
                  menu.when === LOGGED &&
                  (menus || []).map(m => m.name).includes(x.key))
            ),
          };
        }

        return menu;
      })
    );
  }, [isAuthenticated, menus, position]);

  return finalMenu;
}
