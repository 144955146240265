/**
 * List of Ant design locales
 * https://ant.design/docs/react/i18n
 */
import enUS from 'antd/es/locale/en_US';
import esES from 'antd/es/locale/es_ES';
import { LANGUAGES } from '../constants';

export default (language = LANGUAGES.default) => {
  switch (language) {
    case LANGUAGES.en: {
      return enUS;
    }
    default:
    case LANGUAGES.es: {
      return esES;
    }
  }
};
