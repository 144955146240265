import gql from 'graphql-tag';

export const IDENTIFICATION_TYPES = gql`
  query IdentificationTypes {
    identificationTypes {
      id
      name
    }
  }
`;
